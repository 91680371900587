import * as React from 'react';
import { match } from "react-router";
import { InvalidLink } from '../../../components/Common/InvalidLink';
import { DraftStatusView } from './DraftStatusView';
import { TaxReturnStatus} from "../../../core/common/Enums";
import { Guid } from "../../../core/utilities/Guid";

export class TaxReturnStatusView extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
	}
	componentDidMount() {
		if(this.props.match.params.clientId && !Guid.isValid(this.props.match.params.clientId)){
			this.props.requestCoverClientData(
				this.props.match.params.clientId,
				this.goToErrorPage
			  );
			this.props.requestHeaderDetails(this.props.match.params.clientId);
		}
	}

	goToErrorPage = () => {
		this.props.history.push("/Error");
	};

	public render() {
		let params: any = this.props.match.params;
		let taxReturnStatus = parseInt(params.status);
		switch (taxReturnStatus) {
			case TaxReturnStatus.DraftReviewed:
			case TaxReturnStatus.DraftCompleted:
				return <DraftStatusView 
						taxReturnStatus={taxReturnStatus}
						match={this.props.match}
                    	history={this.props.history}
                    	headerInfoState={this.props.headerInfoState}
						initialCoverState={this.props.initialCoverState}
						source={params.source}
						getClientHubDomainURL={this.props.getClientHubDomainURL}
					/>
			default:
				return <InvalidLink/>
		}
	}
}

export default TaxReturnStatusView;
