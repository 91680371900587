import * as React from 'react';
import { TaxReturnStatus, RedirectionSourcePage} from "../../../core/common/Enums";
import HeaderComp from "../../Header/HeaderComp";
import Skeleton from "react-loading-skeleton";
import { setCookie, getCookie} from "../../../components/Helper/HelperFunction";

interface DraftStatusViewState {
	refresh: boolean;
}

interface DraftStatusViewProps {
    match: any;
  	history: any;
	taxReturnStatus: TaxReturnStatus;
	initialCoverState: any;
	headerInfoState: any;
	source?: RedirectionSourcePage; 
	getClientHubDomainURL?: (clientGuid: string, callback: (onehubRedirectURL: string) => void) => void;
}

const taxstatusviewcompanyLogoKey = "draftstatusview-companylogo"

export class DraftStatusView extends React.Component<DraftStatusViewProps, DraftStatusViewState> {
	private companyLogoPath: string = '';
	private rendered: boolean = false;
	
	constructor(props: DraftStatusViewProps, states: DraftStatusViewState) {
        super(props, states);
		this.state = {
            refresh: false,
		}
    }

	componentDidMount() {
		const logoPath = getCookie(taxstatusviewcompanyLogoKey);
		if(this.props.initialCoverState?.logoPath){
			this.setCompanyLogo(this.props.initialCoverState?.logoPath);
			setCookie(taxstatusviewcompanyLogoKey, this.companyLogoPath);
			return;
		}
		this.setCompanyLogo(logoPath);
		if(!this.rendered){
			this.setState({refresh: true}, () => {
				this.rendered = true;
			});
		}
	}

	componentWillReceiveProps(nextProps: DraftStatusViewProps) {
		const logoPath = getCookie(taxstatusviewcompanyLogoKey);
		if(nextProps.initialCoverState?.logoPath && nextProps.initialCoverState?.logoPath != this.props.initialCoverState?.logoPath){
			this.setCompanyLogo(nextProps.initialCoverState?.logoPath);
			setCookie(taxstatusviewcompanyLogoKey, this.companyLogoPath);
			return;
		}
		this.setCompanyLogo(logoPath);
	}

	private setCompanyLogo = (logoPath: string) => {
		if(logoPath){
			this.companyLogoPath = logoPath;
		}
	}

	public render() {
		return <>
				<HeaderComp
					headerInfo={this.props.headerInfoState}
					pathname={this.props.match.path}
					clientId={this.props.match.params.clientId}
					history={this.props.history}
					backButton={false}
					navigateLink=""
					source={this.props.source}
					match={this.props.match}
					getClientHubDomainURL={this.props.getClientHubDomainURL}
				/>

				{this.props.initialCoverState?.loaderState === true ? 
					(
            			<Skeleton circle={false} height={"100vh"} width={"100vw"} />
          			) : 
					(
						this.getDrafStatusView()
					)
				}
			</>
	}


	getDrafStatusView = () => {
		const { taxReturnStatus } = this.props;
		const statusViewPadding = taxReturnStatus == TaxReturnStatus.DraftCompleted ? "draftstatus-completed-padding": "draftstatus-reviewed-padding";
		return (
			<>
				<div className={`$layout-container draftstatus-page d-flex align-items-center justify-content-center ${statusViewPadding}`}>
					<div className="row">
						<div className="col-lg-8 col-md-7 col-sm-6">
							<div>
								{
									this.companyLogoPath.length !== 0 ? (
									<img
									className="draftstatus-companylogo float-left"
									data-test-auto="VM1Y507X3KAMT985AC3UCPACMQ"
									src={this.companyLogoPath}
									alt="company-logo"
									/>
								) : ""
								}
							</div>
							<div className="draft-status-container draftstatus-message text-left">
								{ 
									taxReturnStatus == TaxReturnStatus.DraftReviewed && 
									<div>
										<div style={{ color: "#0973BA" }}>Thank You,</div>
										<div>for completing your review.</div>		
									</div>
								}
								{
									taxReturnStatus == TaxReturnStatus.DraftCompleted && 
										<div>
											<div>This task has already</div>	
											<div>been completed.</div>		
										</div>
								}
							</div>
							<div className="margin-top-30">
								<div>
									<div className="draftstatus-cpa-text text-left" data-test-auto="DKL160X9V0A45ATKDN4REGDFE1">
										Your CPA will be in touch shortly.
									</div>
									<div className="margin-top-30 text-left draftstatus-closewindow-text" data-test-auto="DKL160X9V0A45ATKDN4REGDFE1">
										You can now close this window.
									</div>
								</div>
							</div>
							</div>
							<div className="col-lg-4 col-md-5 col-sm-6" style={{paddingTop : "50px"}}>
								<div className="margin-top-30">
									{
										taxReturnStatus != TaxReturnStatus.DraftCompleted ?
											<img
											style={{
											alignSelf: "center",
											padding: 0,
											}}
											src={
											require("../../../assets/images/DraftStatus-Reviewed.svg")
												.default
											}
											alt="welcome-screen-logo"
											/>:
											<img
												style={{
												alignSelf: "center",
												padding: 0,
												}}
												src={
												require( "../../../assets/images/DraftStatus-Completed.svg")
													.default
												}
												alt="welcome-screen-logo"
											/>
									}
								</div>
							</div>
						</div>
				</div>
			</>
		)
	}
}


