import { Step } from "@sssuite-component-ui/multi-step-wizard/dist/Body/Step";
import { Wizard } from "@sssuite-component-ui/multi-step-wizard/dist/Body/Wizard";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { History } from "history";
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { match, RouteComponentProps } from "react-router";
import {
  ClientType,
  DistributeType,
  DocumentStatus,
  EngagementTypeS,
  SignatureType,
  DraftStatus,
} from "../../core/common/Enums";
import { ICompany } from "../../core/domain/models/company/Company";
import { IDocument } from "../../core/domain/models/esign/Document";
import { IDocumentSettings } from "../../core/domain/models/IDocumentSettings";
import {
  DocumentGroups,
  IVoucher,
  IGroup,
  VoucherTypes,
} from "../../core/domain/models/IGroup";
import {
  GetK1EngagementType,
  IK1ShareHolderDetails,
} from "../../core/domain/models/IK1Distribute";
import {
  initailsignatureSettingsModel,
  ISignatureSettingsModel,
} from "../../core/domain/models/ISignatureSettingsModel";
import { ITaxDocumentModel } from "../../core/domain/models/ITaxDocumentModel";
import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import { ISignedDocument } from "../../core/domain/models/manualsign/SignedDocument";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { ICommonDataViewModel } from "../../core/domain/viewModels/ICommonDataViewModel";
import { IDocumentReviewModel } from "../../core/domain/viewModels/IDocumentReviewModel";
import {
  IHeaderInfoViewModel,
  initialHeaderInfoViewModel,
} from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { ILoggedInUserInfo } from "../../core/domain/viewModels/ILoggedInUserInfo";
import { IPayScreenViewModel } from "../../core/domain/viewModels/IPayScreenViewModel";
import { ISignProcessInfoViewModel } from "../../core/domain/viewModels/ISignProcessInfoViewModel";
import { ISummaryViewModel } from "../../core/domain/viewModels/ISummaryViewModel";
import { ITutorialInfo } from "../../core/domain/models/ITutorialInfo";
import { IDialogBox } from "../../core/utilities/ui/DialogBox";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import * as AccountStore from "../../store/Common/AccountStore";
import * as CompanyStore from "../../store/Common/Company/CompanyStore";
import * as EsignStore from "../../store/Common/EsignStore";
import * as HelperStore from "../../store/Common/HelperStore";
import * as TaxDocumentStore from "../../store/Common/TaxDocumentStore";
import * as K1DistributeStore from "../../store/K1/K1DistributeStore";
import * as PaperFileReturnStore from "../../store/PaperFileReturn/PaperFileReturnStore";
import * as PayStore from "../../store/PayStore";
import * as PreparerMessageStore from "../../store/PreparerMessageStore";
import * as DocumentReviewStore from "../../store/ReviewStore";
import * as ReviewStore from "../../store/ReviewStore";
import * as ManualSignStore from "../../store/Sign/ManualSignStore";
import * as SignProcessStore from "../../store/SignProcess/SignProcessStore";
import * as TutorialStore from "../../store/Tutorial/TutorialStore";
import * as SummaryStore from "../../store/SummaryStore";
import * as PaperFileMessageWizard from "../PaperFile/PaperFileMessageWizard";
import { PaperFileList } from "../PaperFile/PaperFileList";
import { PaperFileReturnConstants } from "../Common/Constants";
import {
  Common,
  K1ShareHolderConstants,
  ManualSignConstants,
  PathConstants,
  VoucherPaymentConstants,
} from "../Common/Constants";
import {
  SvgIconDistributeK1sWizardActive,
  SvgIconMakePaymentsWizardActive,
  SvgIconDownloadWizardActive,
  SvgIconReviewWizardActive,
  SvgIconSignWizardActive,
  SvgIconSummaryWizardActive,
  SvgIconMakePaymentDisabledWizardActive,
  SvgIconPaperFileReturnsWizardActive,
  PreviousButtonIcon,
  NextButtonIcon,
  TemplateIcon,
} from "../Common/Icons/SvgIcons";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import { Body } from "../Common/StepLayout/Body";
import { Header } from "../Common/StepLayout/Header";
import { StepLayout } from "../Common/StepLayout/StepLayout";
import { EsignConsentEmail } from "../Esign/EsignConsentEmail";
import { Esign } from "../Esign/Main/Esign";
import SelectSignMethod from "../Esign/SelectSignMethod";
import SpouseSignRequired from "../Esign/SpouseSignRequired";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { DistributeElectronic } from "../K1Distribution/DistributeElectronic";
import { DistributeManual } from "../K1Distribution/DistributeManual";
import { K1DistributionMethod } from "../K1Distribution/K1DistributionMethod";
import { ManualSign } from "../ManualSign/ManualSign";
import { ManualSignDownloadFiles } from "../ManualSign/ManualSignDownloadFiles";
import { IPaperFileReturnDetails } from "../../core/domain/models/IPaperFileReturn";
import * as PayWizard from "../Pay/Main/Pay";
import { PaymentInstruction } from "../Pay/PaymentInstruction";
import * as PreparerMessageWizard from "../PreparerMessage/MessageWizard";
import * as InvoiceWizard from "../Review/Main/Invoice";
import * as ReviewWizard from "../Review/Main/Review";
import * as SummaryWizard from "../Summary/Main/WizardBody";
import {
  SignProcessSteps,
  TaxpayerSignFlowManager,
} from "./TaxpayerSignFlowManager";
import {
  hideReviewStep,
  isAnyPaySettingsEnabled,
  isAttachedUrlEnabled,
  isIndividualEngagementType,
  isStripeEnabled,
} from "../Helper/HelperFunction";
import * as PaymentStore from "../../store/PaymentStore";
import { IPaymentTransactionModel } from "../../core/domain/models/IPaymentTransactionModel";
import { IAdditionalEsignDocument } from "../../core/domain/models/IAdditionalEsignDocument";
import SignatureCompletedPage from "../Esign/SignatureCompletedPage";
import PaymentVoucher from "../Pay/PaymentVoucher/PaymentVoucher";
import EstimatedVoucher from "../Pay/EstimatedVoucher/EstimatedVoucher";
import * as DocumentStatusStore from "../../store/Common/DocumentStatusStore";
import { logger } from "../../routes";
import * as TrialK1DistributeStore from "../../store/K1/TrialK1DistributeStore";
import { ILastActiveStep, initialLastActiveStep } from "./TaxpayerSignFlow";

const NO_INDEX = -1;

export type PreviewComponentProps = {
  summaryData: ISummaryViewModel;
  esignData: ITaxDocumentModel;
  signerData: ISignerModel[];
  manualSignData: ISignedDocument[];
  companyData: ICompany;
  payScreenData: IPayScreenViewModel;
  signatureControlsData: IDocument[];
  commonData: ICommonDataViewModel;
  signatureSettingModel: ISignatureSettingsModel;
  documentSettings: IDocumentSettings;
  documentReviewModel: IDocumentReviewModel[];
  headInfoViewModel: IHeaderInfoViewModel;
  taxReturn: TaxDocument.ITaxReturn;
  match: match;
  history: History;
  loading: boolean;
  k1Data: IK1ShareHolderDetails[];
  isK1Loading: boolean;
  paperFileReturnData: IPaperFileReturnDetails[];
  signProcessInfo: ISignProcessInfoViewModel;
  previewReady: boolean;
  loggedInUserInfo: ILoggedInUserInfo;
  tutorialInfo: ITutorialInfo;
  additionalEsignDocuments: IAdditionalEsignDocument[];
  downloadAllAdditionEsignDocuments: (
    clientId: string,
    fileName: string
  ) => any;
  downloadAllAdditionEsignDocumentsForPreview: (
    clientId: string,
    fileName: string
  ) => any;
  requestAdditionalEsignDocument(clientGuid: string): void;
  requestMyDownload: (clientId: string) => void;
  paymentData: IPaymentTransactionModel;
  changedSignBehalfSpouse: boolean;
  DocumentStatusState: DocumentStatusStore.IDocumentStatusState;
} & typeof SummaryStore.actionCreators &
  typeof HelperStore.actionCreators &
  typeof EsignStore.actionCreators &
  typeof AccountStore.actionCreators &
  typeof ManualSignStore.actionCreators &
  typeof CompanyStore.actionCreators &
  typeof PreparerMessageStore.actionCreators &
  typeof DocumentReviewStore.actionCreators &
  typeof TaxDocumentStore.actionCreators &
  typeof K1DistributeStore.actionCreators &
  typeof PaperFileReturnStore.actionCreators &
  typeof SignProcessStore.actionCreators &
  typeof ReviewStore.actionCreators &
  typeof PayStore.actionCreators &
  typeof TutorialStore.actionCreators &
  typeof PaymentStore.actionCreators &
  typeof DocumentStatusStore.actionCreators &
  typeof TrialK1DistributeStore.actionCreators &
  RouteComponentProps<{}>;

interface PreviewComponentState {
  signatureSettings: ISignatureSettingsModel;
  headInfoViewModel: IHeaderInfoViewModel;
  hideNextButton: boolean;
  hidePreviousButton: boolean;
  isNextButtonDisable: boolean;
  isConsentAgreed: boolean;
  showModal: boolean;
  isDocumentError: boolean;
  isClientView: boolean;
  hideNavigationBar: boolean;
  hideFooter: boolean;
  isPreviewMode: boolean;
  isSignBehalfOfSpouse: boolean;
  isSpouseBehalfCheckDisabled: boolean;
  voucherState: string;
  voucherFlow: string;
  isTrialView: boolean;
  isManualSignNextClickDone: boolean;
  isDraftReturn: boolean;
  lastActiveStep: ILastActiveStep;
}

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);
//let timeoutFunc: number;

export class PreviewComponent extends React.Component<
  PreviewComponentProps,
  PreviewComponentState
> {
  private _wizardRef: any;
  private _reviewRef: any;
  private _consentRef: any;
  private _esignRef: any;
  private _spouseSignRequiredRef: any;
  private _paperfileListRef: any = React.createRef();
  private _manualSign: any;
  private _params: any;
  private _taxpayerSignFlowManager: TaxpayerSignFlowManager =
    TaxpayerSignFlowManager.createNullObject();
  private _distributeManualRef: any = React.createRef();
  private _distributeElectronicRef: any = React.createRef();
  private _payRef: any;

  constructor(props: PreviewComponentProps) {
    super(props);
    this.state = {
      signatureSettings: initailsignatureSettingsModel,
      headInfoViewModel: initialHeaderInfoViewModel,
      hideNextButton: false,
      hidePreviousButton: false,
      isNextButtonDisable: false,
      isConsentAgreed: false,
      showModal: false,
      isDocumentError: false,
      isClientView: false,
      hideNavigationBar: false,
      hideFooter: false,
      isPreviewMode: true,
      isSignBehalfOfSpouse: false,
      isSpouseBehalfCheckDisabled: false,
      voucherState: "payment",
      voucherFlow: "forward",
      isTrialView: false,
      isManualSignNextClickDone: false,
      isDraftReturn: false,
      lastActiveStep: initialLastActiveStep,
    };

    this.setEstimated = this.setEstimated.bind(this);
    this.setVoucherState = this.setVoucherState.bind(this);
    this.handleBackVoucher = this.handleBackVoucher.bind(this);
  }

  componentDidMount() {
    let param: any = this.props.match.params;
    this._params = this.props.match.params;
    this.getSignProcessStatus(param.clientId);
    this.props.requestTaxDocument(param.clientId);
    this.props.requestDocumentSettings(param.clientId);
    this.props.requestTutorialInfoPreview(param.clientId);
    this.props.requestStripePublicAPIKey(param.clientId);

    if (
      this.props.commonData &&
      this.props.commonData.taxingAuthorities.length == 0
    ) {
      this.props.requestAllTaxingAuthorities(param.clientId);
    }
    this.props.requestCompanyDetails(param.clientId);
    this.props.requestPaymentTransaction(param.clientId);
    this.props.requestLastVisitedStep(
      this._params.clientId,
      (lastVisitedStep: SignProcessSteps) => {
        this.signProcessRecoverConfirmation(lastVisitedStep);
      }
    );
    !this.state.isClientView &&
      this.props.requestDocumentsForPreview(param.clientId);
    this.props.requestAdditionalEsignDocument(this._params.clientId);
    this.props.getDocumentUpdatedStatus(param.clientId);
    logger.trackPageView(`isPreviewMode --> ${this.state.isPreviewMode}`, {
      ClientId: this._params.clientId,
      DocumentId: this.props.taxReturn.id,
      PreviewMode: this.state.isPreviewMode,
    });
  }

  componentDidUpdate(
    prevProps: Readonly<PreviewComponentProps>,
    prevState: Readonly<PreviewComponentState>,
    snapshot?: any
  ): void {
    if (
      this.props.summaryData.draftStatus !==
        prevProps.summaryData.draftStatus ||
      (this.props.headInfoViewModel.draftStatus === DraftStatus.Draft &&
        !this.state.isDraftReturn)
    ) {
      const newIsDraftReturn =
        this.props.summaryData.draftStatus === DraftStatus.Draft;
      if (this.state.isDraftReturn !== newIsDraftReturn) {
        this.setState({
          isDraftReturn: newIsDraftReturn,
        });
      }
    }

    if (
      prevProps.summaryData !== this.props.summaryData ||
      prevProps.taxReturn !== this.props.taxReturn ||
      prevProps.documentSettings !== this.props.documentSettings ||
      prevState.voucherState !== this.state.voucherState
    ) {
      this.logicForGettingLastStep();
    }
  }

  private logicForGettingLastStep = () => {
    if (!this.state.isDraftReturn) {
      return;
    }

    const hasValidVouchers =
      this.props.taxReturn &&
      TaxpayerHelper.isVoucherExist(this.props.taxReturn);

    const hasK1s =
      this.props.taxReturn.formGroups.find(
        (x) => x.group === DocumentGroups.K1
      ) === undefined
        ? false
        : true;

    const hasPaperFiles =
      !this.props.summaryData.hasPaperFiles ||
      !this.props.documentSettings.deliverySettings.paperFilePreparerMessage
        .body
        ? false
        : true;

    const hasPaymentVoucher = this.props.taxReturn.formGroups
      .find((x) => x.group === DocumentGroups.Vouchers)
      ?.forms?.some((y: any) => y.paymentType === VoucherTypes.PaymentVoucher);

    const hasEstimatedVoucher = this.props.taxReturn.formGroups
      .find((x) => x.group === DocumentGroups.Vouchers)
      ?.forms?.some(
        (y: any) => y.paymentType === VoucherTypes.EstimatedVoucher
      );

    if (!hasValidVouchers && !hasK1s && !hasPaperFiles) {
      this.setState({
        lastActiveStep: {
          ...initialLastActiveStep,
          esignStep: true,
          manualSignStep: true,
        },
      });
    } else if (!hasValidVouchers && hasK1s) {
      this.setState({
        lastActiveStep: {
          ...initialLastActiveStep,
          k1Step: true,
        },
      });
    } else if (!hasValidVouchers && !hasK1s && hasPaperFiles) {
      this.setState({
        lastActiveStep: {
          ...initialLastActiveStep,
          paperfileStep: true,
        },
      });
    } else if (hasValidVouchers) {
      if (
        (hasPaymentVoucher && hasEstimatedVoucher) ||
        (!hasPaymentVoucher && hasEstimatedVoucher)
      ) {
        this.setState({
          lastActiveStep: {
            ...initialLastActiveStep,
            estimatedVoucherStep: true,
          },
        });
      } else if (hasPaymentVoucher && !hasEstimatedVoucher) {
        this.setState({
          lastActiveStep: {
            ...initialLastActiveStep,
            paymentVoucherStep: true,
          },
        });
      }
    }
  };

  setSigningStateForSpouse = () => {
    this.setState(
      { isSignBehalfOfSpouse: true, isSpouseBehalfCheckDisabled: true },
      () => {
        this.props.updateSignBehalfSpouseState();
      }
    );
  };

  checkIfBothSignerDeceased = () => {
    const param: any = this.props.match.params;
    if (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType !== SignatureType.ManualSign &&
      TaxpayerHelper.bothSignerDeceased(
        this.props.taxReturn as any,
        this.props.signerData
      )
    ) {
      this.handleSetSignMethod(SignatureType.ManualSign);
    }
  };

  private getSignProcessStatus = (clientId: any) => {
    this.props.requestCurrentStepAndUserRole(clientId, this.setclientState);
  };

  private setclientState = (clientProcessState: IClientProcessViewModel) => {
    this.setState({
      isClientView:
        TaxpayerHelper.isPreviewModeWithWritePermission(clientProcessState),
      isPreviewMode: TaxpayerHelper.isPreviewMode(clientProcessState),
      isTrialView: TaxpayerHelper.isTrialView(),
    });
  };

  /* TODO Take from props(redux), move the method to SelectSignMethod component */

  handleSetSignMethod = (signatureType: SignatureType) => {
    const param: any = this.props.match.params;

    let tempSignatureSetting: ISignatureSettingsModel = this.state
      .signatureSettings as ISignatureSettingsModel;
    tempSignatureSetting.signatureFormSelectionType = signatureType;
    this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType =
      signatureType;
    this.setState({ signatureSettings: tempSignatureSetting }, () => {
      this.updateSettingsAndRefreshTaxDocument(param.clientId);
    });
  };

  updateSettingsAndRefreshTaxDocument = (clientId: string) => {
    if (
      this.state.signatureSettings.signatureFormSelectionType ===
      SignatureType.ESign
    ) {
      this.goToNextStep();
    } else {
      this.goToStepById(
        this.state.isDraftReturn
          ? SignProcessSteps.ManualSign.toString()
          : SignProcessSteps.ManualSignDownload.toString()
      );
    }
  };

  private isSpouseSignRequired = (): boolean => {
    let param: any = this.props.match.params;
    return (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType == SignatureType.ESign &&
      TaxDocument.isMutual(this.props.taxReturn) &&
      this.props.taxReturn.engagementType == EngagementTypeS.E1040 &&
      TaxpayerHelper.isMarried(this.props.taxReturn) &&
      TaxpayerHelper.isFirstSignerLoggedIn(
        this.props.taxReturn,
        this.props.signerData,
        param.clientId
      ) &&
      !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn)
    );
  };

  private isSecondSignerExists = (): boolean => {
    let param: any = this.props.match.params;
    return (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType == SignatureType.ESign &&
      TaxDocument.isMutual(this.props.taxReturn) &&
      TaxpayerHelper.isMarried(this.props.taxReturn) &&
      ClientType.Undefined !=
        TaxpayerHelper.getSecondSignerType(
          this.props.taxReturn,
          this.props.signerData,
          param.clientId
        ) &&
      !TaxpayerHelper.isNextSignerDeceased(this.props.taxReturn)
    );
  };

  private hideSignComplete = (): boolean => {
    if (
      this.props.taxReturn.documentSettings.documentSignatureSetting
        .signatureFormSelectionType != SignatureType.ESign
    ) {
      return true;
    } else {
      if (this.isSecondSignerExists()) {
        return true;
      } else {
        return false;
      }
    }
  };

  private clearState = () => {
    this.setState({
      hideNextButton: false,
      hidePreviousButton: false,
      isNextButtonDisable: false,
    });
  };

  goToStepById = (id: any) => {
    this._wizardRef && this._wizardRef.goToStepById(id);
  };

  goToStep = (stepNumber: number) => {
    this._wizardRef && this._wizardRef.goToStep(stepNumber);
  };

  goToNextStep = () => {
    this._wizardRef && this._wizardRef.goToNextStep();
  };

  goToPreviousStep = () => {
    this._wizardRef && this._wizardRef.goToPreviousStep();
  };

  goToCompletionWizard = (isDocSettingsUpdated: boolean = false) => {
    const param: any = this.props.match.params;
    this.props.history.push(
      this._params.controllerId
        ? `${PathConstants.CompletionWizard}${this._params.controllerId}/${param.clientId}`
        : `${PathConstants.CompletionWizard}${param.clientId}`
    );
  };

  handleHeaderAndFooterVisibility = (visibility: boolean) => {
    this.setState({ hideNavigationBar: visibility, hideFooter: visibility });
  };

  disableNextButton = (value: boolean) => {
    this.setState({ isNextButtonDisable: value });
  };

  onReviewWizardPreviousBtnClick = () => {
    this._reviewRef.onPrevious(this._reviewRef.state.currentStep);
  };

  onReviewWizardNextBtnClick = () => {
    this._reviewRef.onNext(this._reviewRef.state.currentStep);
  };

  onConsentWizardNextBtnClick = (currStep: number) => {
    this._consentRef.onNext(currStep);
  };

  onConsentWizardPreviousBtnClick = (currStep: number) => {
    this._consentRef.onPrevious(currStep);
  };

  onEsignWizardNextBtnClick = (currStep: number) => {
    this._esignRef.onNext(currStep);
  };

  onSpouseSignRequiredNextBtnClick = (currStep: number) => {
    this._spouseSignRequiredRef.onNext(currStep);
  };

  onManualDownloadNextBtnClick = () => {
    this.setState({ isManualSignNextClickDone: true });
  };

  onConsentAgreed = (value: boolean) => {
    this.disableNextButton(!value);
    this.setState({ isConsentAgreed: value });
  };

  onSignOnBehalfOfSpouse = (value: boolean) => {
    this.setState({ isSignBehalfOfSpouse: value }, () => {
      this.props.updateSignBehalfSpouseState();
    });
  };

  onEsignComplete = () => {
    this.goToNextStep();
  };

  onPaperFileListPreviousBtnClick = () => {
    this.goToPreviousStep();
  };

  onPaperFilePrepareMessageNextBtnClick = (currStep: number) => {
    this.goToNextStep();
  };

  onPaperFileListNextBtnClick = (currStep: number) => {
    this.goToNextStep();
  };

  onK1DistributionNextBtnClick = (currStep: number) => {
    this.goToStepById(SignProcessSteps.Pay.toString());
  };
  onK1DistributionPreviousBtnClick = () => {
    this.goToStepById(SignProcessSteps.DistributeSelection.toString());
  };

  private k1DistributionMethodConfirm = (currStep: number) => {
    dialogBox.confirm(
      Common.DialogBox.WarningTitle,
      K1ShareHolderConstants.WarningMessage.K1DistributionWarning,
      K1ShareHolderConstants.WarningMessage.Cancel,
      K1ShareHolderConstants.WarningMessage.Confirm,
      (result: boolean) => {
        result && this.onK1DistributionNextBtnClick(currStep);
      }
    );
  };

  handleSetDistributeMethod = (type: DistributeType) => {
    DistributeType.Manual === type
      ? this.goToStepById(SignProcessSteps.DistributeManual.toString())
      : this.goToStepById(SignProcessSteps.DistributeElectronic.toString());
  };

  handleStepChange = (step: number, stepId: string) => {};

  handleSignCompletion = () => {
    const param: any = this.props.match.params;
    if (this.state.isClientView && !this.state.isDraftReturn) {
      this.props.history.push(
        this._params.controllerId
          ? `${PathConstants.CompletionWizard}${this._params.controllerId}/${param.clientId}`
          : `${PathConstants.CompletionWizard}${param.clientId}`
      );
    } else {
      this.goToStep(0);
    }
  };

  handleSubmit = () => {
    if (
      (this.state.isDraftReturn &&
        this.state.voucherState === "payment" &&
        this.state.lastActiveStep.paymentVoucherStep) ||
      (this.state.isDraftReturn &&
        this.state.voucherState === "estimated" &&
        this.state.lastActiveStep.estimatedVoucherStep)
    ) {
      this.handleSignCompletion();
      return;
    }

    if (this.state.voucherState === "payment") {
      return this.setState({
        voucherState: "estimated",
        voucherFlow: "forward",
      });
    } else {
      this.handleSignCompletion();
    }
  };

  handleDraftApprovalOrRejection = () => {
    this.handleSignCompletion();
  };

  handleSubmitForNoVoucher = () => {
    if (this.state.voucherState === "payment") {
      if (this.state.voucherFlow === "forward") {
        return this.setState({ voucherState: "estimated" });
      } else {
        return this.setState({ voucherFlow: "forward" }, () => {
          this.goToPreviousStep();
        });
      }
    } else {
      this.handleSignCompletion();
    }
  };

  initiateDocumentStatusPolling = (
    retrycount: number,
    step: number,
    callback: (step?: number) => void
  ) => {
    this.setState({ showModal: true, isDocumentError: false });
    const param: any = this.props.match.params;
    let timeout: number = 10000;
    if (retrycount > 10) {
      timeout = 60000;
    }
    if (retrycount == 16) {
      this.setState({ showModal: false, isDocumentError: true });
      return false;
    }
    if (!this.props.previewReady) {
      this.props.requestDocumentPreviewStatus &&
        this.props.requestDocumentPreviewStatus(
          param.clientId,
          SignProcessSteps.PreparerMessage,
          (step?: number) => {
            this.props.requestDocumentsForPreview(param.clientId, () => {
              callback(step);
            });
          }
        );
      setTimeout(
        this.initiateDocumentStatusPolling,
        timeout,
        retrycount + 1,
        SignProcessSteps.PreparerMessage,
        callback
      );
    }
  };

  signProcessRecoverConfirmation = (lastVisitedStep: SignProcessSteps) => {
    if (Number(lastVisitedStep) > 0) {
      //Can't continue from this step without placing signature again
      if (lastVisitedStep == SignProcessSteps.SpouseSignature) {
        this.goToStepById(SignProcessSteps.Esign);
      } else {
        this.goToStepById(lastVisitedStep);
      }
    }
  };

  disableNavigationLink = (step: SignProcessSteps) => {
    let index = NO_INDEX;
    index =
      this.props.signProcessInfo &&
      this.props.signProcessInfo.visitedSteps &&
      this.props.signProcessInfo.visitedSteps.indexOf(step);
    if (index == null || (index != null && index === NO_INDEX)) {
      return true;
    } else {
      return false;
    }
  };

  confirmRedirectToPay = () => {
    dialogBox.confirm(
      Common.DialogBox.ConfirmationTitle,
      ManualSignConstants.ConfirmMessage,
      Common.DialogBox.Cancel,
      Common.DialogBox.Confrim,
      (result: boolean) => {
        result && this.goToNextStep();
      }
    );
  };

  private isSigningProcessCompleted = (): boolean | undefined => {
    const index =
      this.props && this.props.signProcessInfo.visitedSteps
        ? this.props.signProcessInfo.visitedSteps.filter(
            (x) =>
              x === SignProcessSteps.Pay ||
              x === SignProcessSteps.DistributeSelection ||
              x === SignProcessSteps.SignComplete ||
              x === SignProcessSteps.PaperFileList
          )
        : null;
    return (
      (index !== null && index.length > 0) ||
      this.props.taxReturn.documentStatus == DocumentStatus.USERSIGNED
    );
  };

  private isLinkVisited = (step: SignProcessSteps): boolean | undefined => {
    let index = NO_INDEX;
    index =
      this.props && this.props.signProcessInfo.visitedSteps
        ? this.props.signProcessInfo.visitedSteps.indexOf(step)
        : NO_INDEX;
    return index !== null && index !== NO_INDEX;
  };

  saveVoucherPayment = (voucher: IVoucher) => {
    dialogBox.alert(VoucherPaymentConstants.AddingVoucherPaymentNotAllowed);
  };

  openInvoiceUrl = () => {
    window.open(this.props.companyData.onlinePaymentUrl, "_blank");
  };

  showCustomBtnInReviewStep = () => {
    if (
      this.props.documentReviewModel.find(
        (x) => x.documentGroup == DocumentGroups.Invoice
      ) != undefined
    ) {
      if (this.props.taxReturn.invoiceAmount <= 0) {
        return false;
      } else if (isAnyPaySettingsEnabled(this.props.companyData)) {
        return true;
      }
    }
    return false;
  };

  setVoucherState = (voucherStateValue: string) => {
    this.setState({ voucherState: voucherStateValue });
  };

  handleBackVoucher = () => {
    this.setState({ voucherState: "payment", voucherFlow: "backward" });
  };

  setEstimated = () => {
    this.setState({ voucherState: "estimated", voucherFlow: "forward" });
  };

  handleVoucherBackButton = () => {
    if (this.state.voucherState === "payment") {
      let self = this;
      this.setState(
        {
          voucherFlow: "forward",
        },
        () => {
          self.goToPreviousStep();
        }
      );
    } else {
      this.setState({ voucherState: "payment", voucherFlow: "backward" });
    }
  };

  public render() {
    const { hideNavigationBar, hideFooter, isPreviewMode, isTrialView } =
      this.state;
    const {
      taxReturn,
      downloadEfileForms,
      downloadPreviewEfileForms,
      downloadPreviewFilingInstruction,
      downloadFilingInstructions,
      downloadPreviewAllVouchers,
      downloadAllVouchers,
      companyData: { name },
    } = this.props;
    const type =
      this.props.taxReturn &&
      GetK1EngagementType(this.props.taxReturn.engagementType);
    const param: any = this.props.match.params;
    const showLoader: boolean =
      this.state.showModal &&
      !this.state.isDocumentError &&
      !this.props.previewReady &&
      !this.state.isClientView;
    const isK1Sent = this.props.k1Data?.filter(
      (x) => x.k1SendDate != undefined
    );
    const SignStepIcon = Common.Images.SignStepIcon;
    const hasValidVouchers =
      this.props.taxReturn &&
      TaxpayerHelper.isVoucherExist(this.props.taxReturn);

    const isEsignLastActiveStep = this.state.lastActiveStep.esignStep;
    const isManualSignLastActiveStep = this.state.lastActiveStep.manualSignStep;
    const isK1LastActiveStep = this.state.lastActiveStep.k1Step;
    const isPaperfileLastActiveStep = this.state.lastActiveStep.paperfileStep;
    const isEstimatedVoucherLastActiveStep =
      this.state.lastActiveStep.estimatedVoucherStep;

    return (
      <Wizard
        hideTopNavigation={hideNavigationBar}
        hideFooter={hideFooter}
        nextButtonAutomationTestId={"FA6F3407-F80B-4FCD-87A5-C094ECF286EC"}
        previousButtonAutomationTestId={"D945DDEF-3701-4155-9529-1C7BDECA4A29"}
        ref={(ref: any) => {
          this._wizardRef = ref;
          this._taxpayerSignFlowManager = TaxpayerSignFlowManager.create(ref);
        }}
        submitLabel={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment" &&
              isEstimatedVoucherLastActiveStep
              ? Common.Wizard.NextButtonLabel
              : Common.Wizard.ApproveDraftReturn
            : this.state.voucherState === "payment"
            ? Common.Wizard.NextButtonLabel
            : "Done"
        }
        onStepChange={this.handleStepChange}
        onSubmit={this.handleSubmit}
        submitIcon={
          this.state.isDraftReturn ? (
            this.state.voucherState === "payment" &&
            isEstimatedVoucherLastActiveStep ? (
              <NextButtonIcon />
            ) : (
              <></>
            )
          ) : this.state.voucherState === "payment" ? (
            <NextButtonIcon />
          ) : (
            <></>
          )
        }
        mainStepTabIndex={0}
        submitBtnVariant={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment" &&
              isEstimatedVoucherLastActiveStep
              ? "primary"
              : "success"
            : this.state.voucherState === "payment"
            ? "primary"
            : "success"
        }
        showLastStepCustomSubmitBtn={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment" &&
              isEstimatedVoucherLastActiveStep
              ? false
              : true
            : false
        }
        lastStepCustomSubmitBtnLabel="Request Changes"
        lastStepCustomSubmitBtnVariant="danger"
        lastStepCustomSubmitBtnOnClick={this.handleDraftApprovalOrRejection}
        lastStepCustomSubmitBtnClassname={"last-step-buttons"}
        submitBtnClassname={
          this.state.isDraftReturn
            ? this.state.voucherState === "payment" &&
              isEstimatedVoucherLastActiveStep
              ? ""
              : "last-step-buttons"
            : ""
        }
      >
        <Step
          id={SignProcessSteps.Summary.toString()}
          tooltip={"Summary"}
          name={"Summary"}
          template={<TemplateIcon />}
          hideNextButton={true}
          hidePreviousButton={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
        >
          {this.state.isDocumentError &&
            MarsNotifier.Error(
              "Unable to generate documents. Please contact support.",
              ""
            )}
          <div>
            <Modal
              dialogClassName="information-popup"
              show={showLoader}
              data-test-auto="3ED090CD-AE60-4267-8768-DEC161BF3E72"
            >
              <Modal.Body>
                <div className="col-lg-12 inline-flex-container download-all-body">
                  Generating your documents Please wait...
                </div>
              </Modal.Body>
            </Modal>
          </div>

          <SummaryWizard.WizardBody
            commonData={this.props.commonData}
            match={this.props.match}
            history={this.props.history}
            summaryData={this.props.summaryData}
            requestSummaryDetails={this.props.requestSummaryDetails}
            goToStep={this.goToStep}
            showPaymentRefundPrint={false}
            isPreviewReady={this.props.previewReady}
            initiateDocumentStatusPolling={
              this.state.isClientView
                ? undefined
                : this.initiateDocumentStatusPolling
            }
            //signProcessInfo={initialSignProcessInfoViewModel}
            signProcessInfo={this.props.signProcessInfo}
            goToStepById={this.goToStepById}
            hideSummaryTutorial={this.props.hideSummaryTutorialPreview}
            tutorialInfo={this.props.tutorialInfo}
            headerInfo={this.props.headInfoViewModel}
            prepareMessageExist={
              this.props.documentSettings.deliverySettings.preparerMessage.body
                ? true
                : false
            }
            goToNextStep={this.goToNextStep}
            isPreviewMode={this.state.isPreviewMode}
            isDraftReturn={this.state.isDraftReturn}
          />
        </Step>

        <Step
          id={SignProcessSteps.PreparerMessage.toString()}
          disableNavigationClick={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          hidden={
            !this.props.documentSettings.deliverySettings.preparerMessage.body
          }
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <PreparerMessageWizard.PreparerMessageWizardBody
            match={this.props.match}
            preparerMessage={
              this.props.documentSettings.deliverySettings.preparerMessage.body
            }
            headerInfo={this.props.headInfoViewModel}
            isDelegatee={false}
            isPreviewMode={this.state.isPreviewMode}
          />
        </Step>

        <Step
          id={SignProcessSteps.Invoice.toString()}
          name={"Review"}
          tooltip={"Review"}
          template={<TemplateIcon />}
          preventNextButtonDefaultBehaviour={
            this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) == undefined
          }
          preventPreviousButtonDefaultBehaviour={
            this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) == undefined
          }
          onNext={this.onReviewWizardNextBtnClick}
          onPrevious={this.onReviewWizardPreviousBtnClick}
          hidden={hideReviewStep(this.props.documentReviewModel)}
          disableNavigationClick={this.disableNavigationLink(
            SignProcessSteps.Invoice
          )}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          showCustomButton={this.showCustomBtnInReviewStep()}
          disableCustomButton={this.state.isDraftReturn}
          customButtonLabel={
            this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) != undefined
              ? isAnyPaySettingsEnabled(this.props.companyData)
                ? Common.Wizard.PayNow
                : undefined
              : undefined
          }
          onCustomButtonClick={
            this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) != undefined
              ? isStripeEnabled(this.props.companyData)
                ? undefined
                : isAttachedUrlEnabled(this.props.companyData)
                ? this.openInvoiceUrl
                : undefined
              : undefined
          }
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          {this.props.documentReviewModel.find(
            (x) => x.documentGroup == DocumentGroups.Invoice
          ) != undefined ? (
            <InvoiceWizard.Invoice
              /* requestPreviewDocuments={this.props.requestDocumentsForPreview}*/
              invoiceDocumentReviewModel={this.props.documentReviewModel}
              match={this.props.match}
              showDownloadButton={false}
              invoiceAmount={this.props.taxReturn.invoiceAmount}
              paymentData={this.props.paymentData}
              requestPaymentIntent={this.props.requestPaymentIntent}
              paymentSetting={this.props.companyData.taxpayerPaymentSettings}
              updatePurchaseTransactionStatus={
                this.props.updatePurchaseTransactionStatus
              }
              isPreviewMode={this.state.isPreviewMode}
              isDraftReturn={this.state.isDraftReturn}
            />
          ) : (
            <ReviewWizard.ReviewWizardBody
              ref={(ref: any) => {
                this._reviewRef = ref;
              }}
              onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
              documentReviewModel={this.props.documentReviewModel}
              downloadAttachment={this.props.downloadIndividualAttachment}
              downloadAllAttachments={this.props.downloadAllAttachments}
              /* requestPreviewDocuments={this.props.requestDocumentsForPreview}*/
              match={this.props.match}
              nextStep={this.goToNextStep}
              prevStep={this.goToPreviousStep}
              hideTitle={hideNavigationBar && hideFooter}
              tutorialInfo={this.props.tutorialInfo}
              hideReviewTutorial={this.props.hideReviewTutorialPreview}
              showDownloadButton={true}
              isPreviewMode={this.state.isPreviewMode}
              summaryData={null}
              headerInfo={null}
              updatePaperFileConsent={null}
              skipButtonText={""}
              isDraftReturn={this.state.isDraftReturn}
            />
          )}
        </Step>

        <Step
          id={SignProcessSteps.Review.toString()}
          preventNextButtonDefaultBehaviour={true}
          preventPreviousButtonDefaultBehaviour={true}
          onNext={this.onReviewWizardNextBtnClick}
          onPrevious={this.onReviewWizardPreviousBtnClick}
          hidden={
            this.props.documentReviewModel.find(
              (x) => x.documentGroup == DocumentGroups.Invoice
            ) == undefined
          }
          disableNavigationClick={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <ReviewWizard.ReviewWizardBody
            ref={(ref: any) => {
              this._reviewRef = ref;
            }}
            onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
            documentReviewModel={this.props.documentReviewModel}
            downloadAttachment={this.props.downloadIndividualAttachment}
            downloadAllAttachments={this.props.downloadAllAttachments}
            /*requestPreviewDocuments={this.props.requestDocumentsForPreview}*/
            match={this.props.match}
            nextStep={this.goToNextStep}
            prevStep={this.goToPreviousStep}
            hideTitle={hideNavigationBar && hideFooter}
            tutorialInfo={this.props.tutorialInfo}
            hideReviewTutorial={this.props.hideReviewTutorialPreview}
            showDownloadButton={true}
            isPreviewMode={this.state.isPreviewMode}
            summaryData={null}
            headerInfo={null}
            updatePaperFileConsent={null}
            skipButtonText={""}
            isDraftReturn={this.state.isDraftReturn}
          />
        </Step>

        <Step
          id={SignProcessSteps.SignMethod.toString()}
          name={"Sign"}
          tooltip={"Sign"}
          template={<TemplateIcon />}
          hidden={
            !(
              this.props.taxReturn.documentSettings.documentSignatureSetting
                .signatureFormSelectionType == SignatureType.ESign &&
              !this.isSigningProcessCompleted()
            )
          }
          hideNextButton={true}
          hidePreviousButton={false}
          onPrevious={this.onConsentWizardPreviousBtnClick}
          disableNavigationClick={this.disableNavigationLink(
            SignProcessSteps.SignMethod
          )}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <SelectSignMethod
            onSetSignMethod={this.handleSetSignMethod}
            isPreviewMode={this.state.isPreviewMode}
            clearParentState={this.clearState}
            checkIfBothSignerDeceased={this.checkIfBothSignerDeceased}
            match={this.props.match}
            taxReturn={this.props.taxReturn}
            signerData={this.props.signerData}
            updateSpouseMailChange={this.props.updateSpouseMail}
            updateSpouseMobileChange={this.props.updateSpouseMobileNumber}
            refreshTaxDocument={this.props.requestTaxDocument}
            isDraftReturn={this.state.isDraftReturn}
          />
        </Step>

        <Step
          id={SignProcessSteps.EsignConsentEmail.toString()}
          hidden={
            !(
              this.props.taxReturn.documentSettings.documentSignatureSetting
                .signatureFormSelectionType == SignatureType.ESign &&
              !this.state.isDraftReturn &&
              isIndividualEngagementType(this.props.taxReturn.engagementType) &&
              !this.isSigningProcessCompleted()
            )
          }
          disableNextButton={this.state.isNextButtonDisable}
          onNext={this.onConsentWizardNextBtnClick}
          onPrevious={this.onConsentWizardPreviousBtnClick}
          preventNextButtonDefaultBehaviour={true}
          preventPreviousButtonDefaultBehaviour={true}
          disableNavigationClick={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <EsignConsentEmail
            ref={(ref: any) => {
              this._consentRef = ref;
            }}
            history={this.props.history}
            match={this.props.match}
            isPreviewMode={this.state.isPreviewMode}
            //updateSpouseMailChange={this.props.updateSpouseMail}
            //updateDOBChange={this.props.updateDOB}
            individual={
              this.props.taxReturn as TaxDocument.IIndividualTaxReturn
            }
            mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
            consent={this.state.isConsentAgreed}
            onConsentAgreed={this.onConsentAgreed}
            nextStep={this.goToNextStep}
            prevStep={this.goToPreviousStep}
            refreshTaxDocument={this.props.requestTaxDocument}
            signerData={this.props.signerData}
            disableNextButton={this.disableNextButton}
            clearParentState={this.clearState}
            signBehalfSpouse={this.state.isSignBehalfOfSpouse}
            onSignOnBehalfOfSpouse={this.onSignOnBehalfOfSpouse}
            setSigningStateForSpouse={this.setSigningStateForSpouse}
            isSpouseBehalfCheckDisabled={this.state.isSpouseBehalfCheckDisabled}
          />
        </Step>

        <Step
          id={SignProcessSteps.Esign.toString()}
          hidden={
            !(
              this.props.taxReturn.documentSettings.documentSignatureSetting
                .signatureFormSelectionType == SignatureType.ESign &&
              !this.isSigningProcessCompleted()
            )
          }
          disableNextButton={false}
          disableNavigationClick={true}
          preventNextButtonDefaultBehaviour={true}
          onNext={
            isEsignLastActiveStep
              ? this.handleDraftApprovalOrRejection
              : this.onEsignWizardNextBtnClick
          }
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={
            isEsignLastActiveStep
              ? Common.Wizard.ApproveDraftReturn
              : Common.Wizard.NextButtonLabel
          }
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={isEsignLastActiveStep ? <></> : <NextButtonIcon />}
          nextButtonVariant={isEsignLastActiveStep ? "success" : "primary"}
          nextButtonClassname={isEsignLastActiveStep ? "last-step-buttons" : ""}
          showCustomButton={isEsignLastActiveStep}
          customButtonLabel={Common.Wizard.RequestChanges}
          customButtonVariant="danger"
          customButtonClassname="last-step-buttons"
          onCustomButtonClick={this.handleDraftApprovalOrRejection}
        >
          <Esign
            ref={(ref: any) => {
              this._esignRef = ref;
            }}
            match={this.props.match}
            history={this.props.history}
            isPreviewMode={this.state.isPreviewMode}
            onHeaderAndFooterVisibility={this.handleHeaderAndFooterVisibility}
            requestSignatureControls={
              this.props.requestPreviewSignatureControls
            }
            requestSignBehalfSpouseSignatureControls={
              this.props.requestSignBehalfSpousePreviewSignatureControls
            }
            signatureControlsData={this.props.signatureControlsData}
            onSigningComplete={this.props.updateSignatureControlsData}
            disableNextButton={this.disableNextButton}
            completeSigningStep={this.onEsignComplete}
            isSpouseSignRequired={this.isSpouseSignRequired()}
            headerInfo={this.props.headInfoViewModel}
            engagementType={this.props.taxReturn.engagementType}
            signBehalfSpouse={this.state.isSignBehalfOfSpouse}
            mutual={this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn}
            signerData={this.props.signerData}
            changedSignBehalfSpouse={this.props.changedSignBehalfSpouse}
            updateChangedSignBehalfSpouseState={
              this.props.updateSignBehalfSpouseState
            }
            isDraftReturn={this.state.isDraftReturn}
          />
        </Step>

        {!this.state.isDraftReturn && (
          <Step
            id={SignProcessSteps.SpouseSignature.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={
              this.isSigningProcessCompleted() &&
              this.isSpouseSignRequired() && <TemplateIcon />
            }
            hidden={!this.isSpouseSignRequired()}
            disableNavigationClick={true}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onSpouseSignRequiredNextBtnClick}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SpouseSignRequired
              ref={(ref: any) => {
                this._spouseSignRequiredRef = ref;
              }}
              companyName={this.props.headInfoViewModel.companyName}
              refreshTaxDocument={this.props.requestTaxDocument}
              mutual={
                this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn
              }
              match={this.props.match}
              isPreviewMode={this.state.isPreviewMode}
              //updateSpouseMailChange={this.props.updateSpouseMail}
              signatureControlsData={this.props.signatureControlsData}
              //sign={this.props.sign}
              gotoNextStep={this.goToNextStep}
              signerData={this.props.signerData}
              signingCompleted={() => this.isSigningProcessCompleted()}
              signBehalfSpouse={this.state.isSignBehalfOfSpouse}
            />
          </Step>
        )}

        {this.isSigningProcessCompleted() && !this.state.isDraftReturn && (
          <Step
            id={SignProcessSteps.SignComplete.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={this.isSigningProcessCompleted() && <TemplateIcon />}
            hidden={this.hideSignComplete()}
            disableNavigationClick={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <SignatureCompletedPage
              match={this.props.match}
              companyName={this.props.headInfoViewModel.companyName}
              isPreviewMode={this.state.isPreviewMode}
            />
          </Step>
        )}

        {!this.state.isManualSignNextClickDone && !this.state.isDraftReturn ? (
          <Step
            id={SignProcessSteps.ManualSignDownload.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={<TemplateIcon />}
            preventNextButtonDefaultBehaviour={true}
            onNext={this.onManualDownloadNextBtnClick}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={Common.Wizard.NextButtonLabel}
            hidden={
              !(
                this.props.taxReturn.documentSettings.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ManualSign
              ) || this.isLinkVisited(SignProcessSteps.ManualSign)
            }
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={<NextButtonIcon />}
          >
            <ManualSignDownloadFiles
              //downloadEfileForms={this.props.downloadEfileForms}
              downloadEfileForms={
                this.state.isClientView
                  ? downloadEfileForms
                  : downloadPreviewEfileForms
              }
              clientId={param.clientId}
              goToNextStep={this.onManualDownloadNextBtnClick}
              additionalEsignDocuments={this.props.additionalEsignDocuments}
              downloadAllAdditionEsignDocuments={
                this.state.isClientView
                  ? this.props.downloadAllAdditionEsignDocuments
                  : this.props.downloadAllAdditionEsignDocumentsForPreview
              }
              clientName={this.props.headInfoViewModel.clientName}
              taxReturn={this.props.taxReturn}
              isPreviewMode={this.state.isPreviewMode}
              updateLastVisitedStep={this.props.updateLastVisitedStep}
            />
          </Step>
        ) : (
          <Step
            id={SignProcessSteps.ManualSign.toString()}
            name={"Sign"}
            tooltip={"Sign"}
            template={
              this.isLinkVisited(SignProcessSteps.ManualSign) && (
                <TemplateIcon />
              )
            }
            hidden={
              !(
                this.props.taxReturn.documentSettings.documentSignatureSetting
                  .signatureFormSelectionType == SignatureType.ManualSign
              )
            }
            preventNextButtonDefaultBehaviour={true}
            previousLabel={Common.Wizard.PreviousButtonLabel}
            nextLabel={
              isManualSignLastActiveStep
                ? Common.Wizard.ApproveDraftReturn
                : Common.Wizard.NextButtonLabel
            }
            //disableNextButton={this.props.manualSignData.length <= 0}
            //onNext={() => { this._manualSign && this._manualSign.completeManualSign() }}
            onNext={
              isManualSignLastActiveStep
                ? this.handleDraftApprovalOrRejection
                : this.goToNextStep
            }
            disableNavigationClick={true}
            previousButtonIcon={<PreviousButtonIcon />}
            nextButtonIcon={
              isManualSignLastActiveStep ? <></> : <NextButtonIcon />
            }
            nextButtonVariant={
              isManualSignLastActiveStep ? "success" : "primary"
            }
            nextButtonClassname={
              isManualSignLastActiveStep ? "last-step-buttons" : ""
            }
            showCustomButton={isManualSignLastActiveStep}
            customButtonLabel={Common.Wizard.RequestChanges}
            customButtonVariant="danger"
            customButtonClassname="last-step-buttons"
            onCustomButtonClick={this.handleDraftApprovalOrRejection}
          >
            <StepLayout className="manual-sign-stepper">
              <Body
                title={ManualSignConstants.Title}
                lg={8}
                md={12}
                sm={12}
                xs={12}
                containerClassName={"pt-5"}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
              >
                <ManualSign
                  match={this.props.match}
                  //downloadEfileForms={this.props.downloadEfileForms}
                  //addSignedDocument={this.props.addSignedDocument}
                  //deleteSignedDocument={this.props.deleteSignedDocument}
                  requestSignedDocuments={this.props.requestSignedDocuments}
                  signedDocuments={this.props.manualSignData}
                  companyInfo={this.props.companyData}
                  downloadEfileForms={
                    this.state.isClientView
                      ? downloadEfileForms
                      : downloadPreviewEfileForms
                  }
                  requestCompanyDetails={this.props.requestCompanyDetails}
                  //completeManualSign={this.props.completeManualSign}
                  onCompletion={this.confirmRedirectToPay}
                  ref={(ref: any) => {
                    this._manualSign = ref;
                  }}
                  additionalEsignDocuments={this.props.additionalEsignDocuments}
                  downloadAllAdditionEsignDocuments={
                    this.state.isClientView
                      ? this.props.downloadAllAdditionEsignDocuments
                      : this.props.downloadAllAdditionEsignDocumentsForPreview
                  }
                  requestAdditionalEsignDocument={
                    this.props.requestAdditionalEsignDocument
                  }
                  clientName={this.props.headInfoViewModel.clientName}
                  taxReturn={this.props.taxReturn}
                  isDelegatee={false}
                  isPreviewMode={this.state.isPreviewMode}
                  isDraftReturn={this.state.isDraftReturn}
                />
              </Body>
            </StepLayout>
          </Step>
        )}

        {this.props.taxReturn.id > 0 &&
          this.props.summaryData.hasPaperFiles === true && (
            <Step
              id={SignProcessSteps.PaperFileMessage.toString()}
              name={"PaperFile"}
              tooltip={"Print and Mail Paper File Returns"}
              template={
                this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body && <TemplateIcon />
              }
              hidden={
                !this.props.summaryData.hasPaperFiles ||
                !this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body
              }
              preventNextButtonDefaultBehaviour={true}
              previousLabel={Common.Wizard.PreviousButtonLabel}
              nextLabel={Common.Wizard.NextButtonLabel}
              disableNextButton={false}
              disableNavigationClick={true}
              onNext={this.onPaperFilePrepareMessageNextBtnClick}
              previousButtonIcon={<PreviousButtonIcon />}
              nextButtonIcon={<NextButtonIcon />}
            >
              <PaperFileMessageWizard.PaperFileMessageWizardBody
                preparerMessage={
                  this.props.documentSettings.deliverySettings
                    .paperFilePreparerMessage.body
                }
                headerInfo={this.props.headInfoViewModel}
                isDelegatee={false}
                match={this.props.match}
                isPreviewMode={this.state.isPreviewMode}
                isDraftReturn={this.state.isDraftReturn}
              />
            </Step>
          )}
        {this.props.taxReturn.id > 0 &&
          this.props.summaryData.hasPaperFiles === true && (
            <Step
              id={SignProcessSteps.PaperFileList.toString()}
              name={"PaperFile"}
              tooltip={"Print and Mail Paper File Returns"}
              template={
                !this._taxpayerSignFlowManager.isLinkVisited(
                  SignProcessSteps.PaperFileMessage
                ) &&
                !this.props.documentSettings.deliverySettings
                  .paperFilePreparerMessage.body && <TemplateIcon />
              }
              hidden={!this.props.summaryData.hasPaperFiles}
              customButtonIcon={""}
              enableCustomButton={false}
              disableNavigationClick={false}
              previousLabel={Common.Wizard.PreviousButtonLabel}
              nextLabel={
                isPaperfileLastActiveStep
                  ? Common.Wizard.ApproveDraftReturn
                  : Common.Wizard.NextButtonLabel
              }
              preventPreviousButtonDefaultBehaviour={true}
              onPrevious={this.onPaperFileListPreviousBtnClick}
              onNext={
                isPaperfileLastActiveStep
                  ? this.handleDraftApprovalOrRejection
                  : this.onPaperFileListNextBtnClick
              }
              preventNextButtonDefaultBehaviour={true}
              previousButtonIcon={<PreviousButtonIcon />}
              nextButtonIcon={
                isPaperfileLastActiveStep ? <></> : <NextButtonIcon />
              }
              nextButtonVariant={
                isPaperfileLastActiveStep ? "success" : "primary"
              }
              nextButtonClassname={
                isPaperfileLastActiveStep ? "last-step-buttons" : ""
              }
              showCustomButton={isPaperfileLastActiveStep}
              customButtonLabel={Common.Wizard.RequestChanges}
              customButtonVariant="danger"
              onCustomButtonClick={this.handleDraftApprovalOrRejection}
              customButtonClassname="last-step-buttons"
            >
              <StepLayout>
                <Body
                  lg={10}
                  md={12}
                  sm={12}
                  xs={12}
                  automationTestId={"61BB28B5-42F3-4571-B567-20700B1740B6"}
                >
                  <PaperFileList
                    ref={(ins) => (this._paperfileListRef = ins)}
                    clientName={this.props.headInfoViewModel.clientName}
                    requestPaperFileReturnDetails={
                      this.props.requestPaperFileReturnDetails
                    }
                    paperFiledRetursData={this.props.paperFileReturnData}
                    match={this.props.match}
                    isPreviewMode={true}
                    taxReturn={this.props.taxReturn}
                    signerData={this.props.signerData}
                    downloadAllPaperFileReturns={
                      this.props.downloadAllPaperFileReturns
                    }
                    downloadIndividualPaperFileReturn={
                      this.props.downloadIndividualPaperFileReturn
                    }
                    getPaperFileDocumentStream={
                      this.props.getPaperFileDocumentStream
                    }
                    getMergedPaperFileDocumentStream={
                      this.props.getMergedPaperFileDocumentStream
                    }
                    headerInfo={this.props.headInfoViewModel}
                    addPaperFilePrintToClientTracking={
                      this.props.addPaperFilePrintToClientTracking
                    }
                    addPaperFilePrintAllToClientTracking={
                      this.props.addPaperFilePrintAllToClientTracking
                    }
                    summaryData={this.props.summaryData}
                    updatePaperFileConsent={this.props.updatePaperFileConsent}
                    skipButtonText={PaperFileReturnConstants.SkipButtonText}
                    isDraftReturn={this.state.isDraftReturn}
                  />
                </Body>
              </StepLayout>
            </Step>
          )}
        <Step
          id={SignProcessSteps.DistributeSelection.toString()}
          name={"Distribute"}
          tooltip={"Distribute"}
          template={<TemplateIcon />}
          onNext={
            isK1LastActiveStep
              ? this.handleDraftApprovalOrRejection
              : this.k1DistributionMethodConfirm
          }
          preventNextButtonDefaultBehaviour={true}
          hidden={
            this.props.taxReturn.formGroups.find(
              (x) => x.group === DocumentGroups.K1
            ) === undefined
          }
          disableNavigationClick={this.disableNavigationLink(
            SignProcessSteps.DistributeSelection
          )}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={
            isK1LastActiveStep
              ? Common.Wizard.ApproveDraftReturn
              : Common.Wizard.NextButtonLabel
          }
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={isK1LastActiveStep ? <></> : <NextButtonIcon />}
          nextButtonVariant={isK1LastActiveStep ? "success" : "primary"}
          nextButtonClassname={isK1LastActiveStep ? "last-step-buttons" : ""}
          showCustomButton={isK1LastActiveStep}
          customButtonLabel={Common.Wizard.RequestChanges}
          customButtonVariant="danger"
          customButtonClassname="last-step-buttons"
          onCustomButtonClick={this.handleDraftApprovalOrRejection}
        >
          <StepLayout className="select-k1-method-container">
            <Body
              title={`Distribute K-1 Documents to ${type}`}
              subTitle={`Select the method you would like to distribute your K-1's`}
              lg={10}
              md={12}
              sm={12}
              xs={12}
              automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
            >
              <K1DistributionMethod
                match={this.props.match}
                taxReturn={this.props.taxReturn}
                onSetDistributeMethod={this.handleSetDistributeMethod}
                clearParentState={this.clearState}
                isPreviewMode={this.state.isPreviewMode}
                isK1LastActiveStep={isK1LastActiveStep}
              />
            </Body>
          </StepLayout>
        </Step>

        <Step
          id={SignProcessSteps.DistributeManual.toString()}
          hidden={
            isK1LastActiveStep ||
            this.props.taxReturn.formGroups.find(
              (x) => x.group === DocumentGroups.K1
            ) === undefined
          }
          disableNavigationClick={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          preventPreviousButtonDefaultBehaviour={true}
          onPrevious={this.onK1DistributionPreviousBtnClick}
          preventNextButtonDefaultBehaviour={true}
          onNext={this.onK1DistributionNextBtnClick}
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <StepLayout>
            <Body
              height={100}
              automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
            >
              <DistributeManual
                ref={(ins) => (this._distributeManualRef = ins)}
                requestK1ShareHolderDetails={
                  this.props.requestK1ShareHolderDetails
                }
                k1Data={this.props.k1Data}
                match={this.props.match}
                taxReturn={this.props.taxReturn}
                downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                downloadIndividualK1DocumentAsync={
                  this.props.downloadIndividualK1DocumentAsync
                }
                downloadSelectedK1DocumentAsync={
                  this.props.downloadSelectedK1DocumentAsync
                }
                requestMyDownload={this.props.requestMyDownload}
                isPreviewMode={this.state.isPreviewMode}
                isK1Loading={this.props.isK1Loading}
                isTrialView={isTrialView}
                downloadAllK1DocumentsAsync={
                  this.props.downloadAllK1DocumentsAsync
                }
                downloadBulkSelectedK1DocumentAsync={
                  this.props.downloadBulkSelectedK1DocumentForPreviewAsync
                }
                isDraftReturn={this.state.isDraftReturn}
              />
            </Body>
          </StepLayout>
        </Step>

        <Step
          id={SignProcessSteps.DistributeElectronic.toString()}
          hidden={
            isK1LastActiveStep ||
            this.props.taxReturn.formGroups.find(
              (x) => x.group === DocumentGroups.K1
            ) === undefined
          }
          disableNavigationClick={true}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={
            isK1Sent && isK1Sent.length > 0
              ? Common.Wizard.NextButtonLabel
              : Common.Wizard.SendLater
          }
          preventPreviousButtonDefaultBehaviour={true}
          onPrevious={this.onK1DistributionPreviousBtnClick}
          preventNextButtonDefaultBehaviour={true}
          onNext={this.onK1DistributionNextBtnClick}
          disableCustomButton={
            this.state.isClientView
              ? !this.props.documentSettings.deliverySettings
                  .allowUserToDistributeK1
              : true
          }
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <StepLayout>
            <Body
              lg={10}
              md={12}
              sm={12}
              xs={12}
              automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
            >
              <DistributeElectronic
                ref={(ins) => (this._distributeElectronicRef = ins)}
                requestK1ShareHolderDetails={
                  this.props.requestK1ShareHolderDetails
                }
                requestK1ShareHolderActivity={
                  this.props.requestK1ShareHolderActivity
                }
                k1Data={this.props.k1Data}
                match={this.props.match}
                taxReturn={this.props.taxReturn}
                updateK1ShareHolderEmail={this.props.updateK1ShareHolderEmail}
                updateK1ShareHolderDetail={this.props.updateK1ShareHolderDetail}
                downloadK1DocumentAsync={this.props.downloadK1DocumentAsync}
                downloadSelectedK1DocumentAsync={
                  this.props.downloadSelectedK1DocumentAsync
                }
                downloadIndividualK1DocumentAsync={
                  this.props.downloadIndividualK1DocumentAsync
                }
                sendMailToShareHolder={
                  this.state.isClientView
                    ? this.props.sendMailToShareHolder
                    : undefined
                }
                requestCurrentStepAndUserRole={
                  this.props.requestCurrentStepAndUserRole
                }
                loggedInUserInfo={this.props.loggedInUserInfo}
                companyName={name || ""}
                isPreviewMode={isPreviewMode}
                requestMyDownload={this.props.requestMyDownload}
                isK1Loading={this.props.isK1Loading}
                isTrialView={isTrialView}
                downloadAllK1DocumentsAsync={
                  this.props.downloadAllK1DocumentsAsync
                }
                bulkSendMailToShareHolder={
                  this.state.isClientView
                    ? this.props.bulkSendMailToShareHolder
                    : undefined
                }
                downloadBulkSelectedK1DocumentAsync={
                  this.props.downloadBulkSelectedK1DocumentForPreviewAsync
                }
                isClientView={this.state.isClientView}
                isDraftReturn={this.state.isDraftReturn}
              />
            </Body>
          </StepLayout>
        </Step>

        <Step
          id={SignProcessSteps.Pay.toString()}
          tooltip={hasValidVouchers ? "Pay" : "No Payment Voucher Available"}
          name={"Pay"}
          template={
            <TemplateIcon
              className={hasValidVouchers ? "" : "cursorDisabled"}
            />
          }
          disableNavigationClick={this.disableNavigationLink(
            SignProcessSteps.Pay
          )}
          previousLabel={Common.Wizard.PreviousButtonLabel}
          nextLabel={Common.Wizard.NextButtonLabel}
          preventNextButtonDefaultBehaviour={
            this.state.voucherState === "payment"
          }
          onNext={this.setEstimated}
          onPrevious={this.handleVoucherBackButton}
          preventPreviousButtonDefaultBehaviour={true}
          previousButtonIcon={<PreviousButtonIcon />}
          nextButtonIcon={<NextButtonIcon />}
        >
          <StepLayout className="pay-page-stepper">
            <Body
              lg={12}
              md={12}
              sm={12}
              xs={12}
              noBackgroundColor={true}
              containerClassName="pay-panel-body no-padding"
              bodyClassName={"no-padding no-margin pay-step row"}
              automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
            >
              {this.state.voucherState === "payment" ? (
                <PaymentVoucher
                  ref={(ref: any) => {
                    this._payRef = ref;
                  }}
                  setVoucherState={this.setVoucherState}
                  commonData={this.props.commonData}
                  match={this.props.match}
                  history={this.props.history}
                  payScreenData={this.props.payScreenData}
                  requestPayScreenDetails={this.props.requestPayScreenDetails}
                  documentSettings={this.props.documentSettings}
                  nextStep={this.goToCompletionWizard}
                  prevStep={this.goToPreviousStep}
                  taxReturn={this.props.taxReturn}
                  downloadAllVouchers={
                    this.state.isClientView
                      ? downloadAllVouchers
                      : downloadPreviewAllVouchers
                  }
                  downloadFilingInstructions={
                    this.state.isClientView
                      ? downloadFilingInstructions
                      : downloadPreviewFilingInstruction
                  }
                  downloadTaxInvoice={this.props.downloadTaxInvoice}
                  downloadSingleVoucher={this.props.downloadSingleVoucher}
                  invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                  saveVoucherPayment={this.saveVoucherPayment}
                  isPreviewMode={this.state.isPreviewMode}
                  onSubmitPay={this.handleSubmitForNoVoucher}
                  clearPaymentDetails={this.saveVoucherPayment}
                  taxpayerPaymentSettings={
                    this.props.companyData.taxpayerPaymentSettings
                  }
                  documentUpdated={
                    this.props.DocumentStatusState.documentUpdated
                  }
                  SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                  isDraftReturn={this.state.isDraftReturn}
                />
              ) : (
                <EstimatedVoucher
                  ref={(ref: any) => {
                    this._payRef = ref;
                  }}
                  setVoucherState={this.setVoucherState}
                  commonData={this.props.commonData}
                  match={this.props.match}
                  history={this.props.history}
                  payScreenData={this.props.payScreenData}
                  requestPayScreenDetails={this.props.requestPayScreenDetails}
                  documentSettings={this.props.documentSettings}
                  nextStep={this.goToCompletionWizard}
                  prevStep={this.goToPreviousStep}
                  taxReturn={this.props.taxReturn}
                  downloadAllVouchers={
                    this.state.isClientView
                      ? downloadAllVouchers
                      : downloadPreviewAllVouchers
                  }
                  downloadFilingInstructions={
                    this.state.isClientView
                      ? downloadFilingInstructions
                      : downloadPreviewFilingInstruction
                  }
                  downloadTaxInvoice={this.props.downloadTaxInvoice}
                  downloadSingleVoucher={this.props.downloadSingleVoucher}
                  invoicePaymentUrl={this.props.companyData.onlinePaymentUrl}
                  saveVoucherPayment={this.saveVoucherPayment}
                  isPreviewMode={this.state.isPreviewMode}
                  onSubmitPay={this.handleSubmitForNoVoucher}
                  clearPaymentDetails={this.saveVoucherPayment}
                  taxpayerPaymentSettings={
                    this.props.companyData.taxpayerPaymentSettings
                  }
                  documentUpdated={
                    this.props.DocumentStatusState.documentUpdated
                  }
                  SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
                  isDraftReturn={this.state.isDraftReturn}
                />
              )}
            </Body>
          </StepLayout>
        </Step>
      </Wizard>
    );
  }
}
