import * as React from 'react';
import { ManualSignConstants } from '../../Common/Constants';
import { CompanyInfo } from './CompanyInfo';
import { UploadDocuments } from './UploadDocuments';
import { ISignedDocument } from '../../../core/domain/models/manualsign/SignedDocument';
import { ICompany } from '../../../core/domain/models/company/Company';
import { SvgIconInfoCircle } from '../../Common/Icons/SvgIcons';


export interface UploadDocumentsProps {
    clientId: string;
    signedDocuments: ISignedDocument[];
    companyInfo: ICompany;
    addSignedDocument?(clientId: string, signedDocument: ISignedDocument, successCallback?: () => void, failureCallback?: () => void): void;
    deleteSignedDocument?(clientId: string, id: number): void;
    requestSignedDocument(clientId: string): void;
    isDelegatee: boolean;
    disableNextButton?(value: boolean): void;
    isUploadDisabled?: boolean;
}

export interface UploadDocumentsState {

}

export class UploadDocumentsArea extends React.Component<UploadDocumentsProps, UploadDocumentsState> {

    public render() {

        const { clientId, signedDocuments, companyInfo, addSignedDocument, deleteSignedDocument,requestSignedDocument, isUploadDisabled  } = this.props;

        return (<div className={"col-lg-12 no-padding upload-documents-area"} data-test-auto="FF0E42CF-9DD1-4370-AFB1-9C29D890F85C">

            <div className={"col-lg-12 no-padding upload-documents-title"}>
                <span data-test-auto={"418960D4-28E5-4B54-97FA-426B49041520"}>
                2. {ManualSignConstants.UploadDocumentTitle}
                </span>
            </div>
             
            <div className={"upload-documents-container no-padding row"}
            style={{pointerEvents: isUploadDisabled ? 'none': 'auto'}}
            >

                <UploadDocuments clientId={clientId}
                    addSignedDocument={addSignedDocument}
                    deleteSignedDocument={deleteSignedDocument}
                    signedDocuments={signedDocuments}
                    requestSignedDocument={requestSignedDocument}
                    isDelegatee={this.props.isDelegatee}
                    disableNextButton={this.props.disableNextButton}
                    isUploadDisabled={isUploadDisabled}
                />

                <CompanyInfo address={companyInfo.address} companyDetails={companyInfo}
                    fax={companyInfo.fax} />
                
            </div>

        </div>);
    }
}
