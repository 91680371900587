import * as React from "react";
import { Home, SvgIconContactHeader } from "../Common/Icons/SvgIcons";
import { Toaster } from "../Common/Notification/MarsNotifier";
import Notification from "../Common/Notification/NotificationContainer";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { handleKeyDown, isDraftReturnReviewed } from "../Helper/HelperFunction";
import { TaxReturnStatus, RedirectionSourcePage } from "../../core/common/Enums";
import { PathConstants } from "../Common/Constants";
import { match } from 'react-router';

export type HeaderProps = {
    headerInfo: any;
    backButton: boolean;
    navigateLink: "/CoverPage/Index" | "/GroupedReturns/CoverPage/Index" | "";
    pathname: string;
    clientId: string;
    history: any;
    match?: match;
    source?: RedirectionSourcePage; 
    getClientHubDomainURL?: (clientGuid: string, callback: (onehubRedirectURL: string) => void) => void;
};

export class HeaderComp extends React.Component<HeaderProps, any> {
    contactRef: any;

    constructor(props: any) {
        super(props);
        this.state = {
            showContactPopover: false,
        };

        this.contactRef = React.createRef();
    }

    componentDidMount(): void {
        this.verifyDrafyStatus(this.props.headerInfo);
        document.addEventListener("mousedown", this.handleClickOutsideContact);
        document.addEventListener("keydown", this.handleEscapeKeyEvent);
    }

    componentWillReceiveProps(nextProps: any) {
        this.verifyDrafyStatus(nextProps.headerInfo);
        document.addEventListener("mousedown", this.handleClickOutsideContact);
        document.addEventListener("keydown", this.handleEscapeKeyEvent);
    }

    componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClickOutsideContact);
        document.removeEventListener("keydown", this.handleEscapeKeyEvent);
    }

    verifyDrafyStatus = (headerInfo: any) => {
        if(isDraftReturnReviewed(headerInfo.signatureStatus) && !this.props.pathname.includes('/taxreturnstatus')){
            this.props.history.push(`${PathConstants.TaxReturnStatus}${TaxReturnStatus.DraftCompleted}/${this.props.clientId}`);
        }
    }

    popOver() {
        return (
            <>
                <div className="popoverDiv">
                    <div className="popoverHeader">Contact Person</div>
                    <div className="popoverBody">
                        <div className="popoverName">Anthea clark</div>
                        <div style={{ textAlign: "left", marginLeft: "5px" }}>
                            <a href="mailto:antheaholmes321@gmail.com">mail.com</a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleClickOutsideContact = (event: any) => {
        if (this.contactRef && this.state.showContactPopover &&
            this.contactRef.current && !this.contactRef.current.contains(event.target)) {
            this.setState({ showContactPopover: false });
        }
    }

    handleEscapeKeyEvent = (event: any) => {
        handleKeyDown(event, () => {
            if (this.state.showContactPopover) {
                this.setState({ showContactPopover: false });
            }
        }, "Escape")
    }

    redirectToOneHub = () => {
        let params: any = this.props.match?.params;
        this.props.getClientHubDomainURL && this.props.getClientHubDomainURL(
          params.clientId,
          (oneHubDomainURL: string) => {
            window.location.href = oneHubDomainURL;
          }
        );
    };

    public render() {
        window.Variables.companyName = this.props.headerInfo.companyName;
        let mailStr = "";
        let contactInfoPopover = <></>;
        if (this.props.headerInfo.contactPerson !== null) {
            mailStr = "mailto:" + this.props.headerInfo.contactPerson.email;

            contactInfoPopover = (
                <Popover id="contactInfoPopover" placement="bottom">
                    <div ref={this.contactRef}>
                        <h3 className="popover-header">Contact Information</h3>
                        <div className="popover-body" data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298">
                            <div className='medium'>{this.props.headerInfo.contactPerson.firstName + " " + this.props.headerInfo.contactPerson.lastName} </div>
                
                            <div className='mail'>
                                <a tabIndex={2} style={{ color: '#0973BA' }} href={mailStr}>{this.props.headerInfo.contactPerson.email}</a>
                            </div>
                        </div>
                    </div>
                </Popover>
            );
        }

        let headerBGColor = "#13294B";
        let headerColorCode = "#FFF";

        let companyLogo = null;

        let path = this.props.pathname;
        let pageName = null;

        pageName = path.split("/")[1];

        let homeNavigate: any = "";

        if (this.props.headerInfo.brandingSettings) {
            headerBGColor = this.props.headerInfo.brandingSettings.coverPageSetting.bgColorCode;
            headerColorCode = this.props.headerInfo.brandingSettings.coverPageSetting.foreColorCode;
        }

        if (this.props.backButton) {
            homeNavigate = (
                <span
                    className="header-contact-info"
                    style={{ color: headerColorCode }}
                    onClick={() => {
                        this.props.history.push(`${this.props.navigateLink}/${this.props.clientId}`);
                    }}
                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.history.push(`${this.props.navigateLink}/${this.props.clientId}`))}
                    tabIndex={3}
                >
                    <a>
                        <Home fillColor={headerColorCode} />
                        <span>Home</span>
                    </a>
                </span>
            );
        }

        if (this.props.source && this.props.headerInfo.companyName) {
            homeNavigate = (
                <span
                    className="header-contact-info"
                    style={{ color: headerColorCode }}
                    onClick={() => this.redirectToOneHub()}
                    onKeyDown={(e: any) => handleKeyDown(e, () => this.props.history.push(this.redirectToOneHub()))}
                    tabIndex={3}
                >
                    <a>
                        <Home fillColor={headerColorCode} />
                        <span>Home</span>
                    </a>
                </span>
            );
        }

        if (this.props.headerInfo.companyWhiteLogoPath.length === 0) {
            companyLogo = <span className={`company-name ${this.props.headerInfo.companyName.length > 50 ? "long-text" : ""}`} style={{ color: headerColorCode}}>{this.props.headerInfo.companyName}</span>;
        }
        else {
            companyLogo = <img className="logo-img" src={this.props.headerInfo.companyWhiteLogoPath} alt="company-white-logo" />;
        }

        return (
            <>
                <Notification />
                <Toaster />
                <header className="app-header" style={{ backgroundColor: headerBGColor, color: headerColorCode }}>
                    <div className="header-left-container" data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B">
                        {companyLogo}
                    </div>
                    <div className="header-right-container" data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B">
                        {homeNavigate}
                        {this.props.headerInfo.contactPerson !== null ? (
                            <span
                                className="header-contact-info"
                                style={{ color: headerColorCode }}
                                title="Contact Person's Information"
                            >
                                <OverlayTrigger
                                    data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
                                    rootClose trigger="click"
                                    onEnter={() => { document.body?.click() }}
                                    overlay={contactInfoPopover}
                                    placement="bottom"
                                    show={this.state.showContactPopover}
                                >
                                    <a
                                        tabIndex={1}
                                        onClick={() => this.setState({ showContactPopover: !this.state.showContactPopover })}
                                        onKeyDown={(e: any) => handleKeyDown(e, () => this.setState({ showContactPopover: !this.state.showContactPopover }))}
                                    >
                                        <SvgIconContactHeader fillColor={headerColorCode} />
                                        <span>Contact</span>
                                    </a>
                                </OverlayTrigger>
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </header>
            </>
        );
    }
}

export default HeaderComp;
