import * as React from 'react';
import * as TaxDocumentStore from '../../../store/Common/TaxDocumentStore';
import * as TaxDocument from '../../../core/domain/models/ITaxReturn';
import { match, RouteComponentProps } from 'react-router';
import { ICompany } from '../../../core/domain/models/company/Company';
import * as CompanyStore from '../../../store/Common/Company/CompanyStore';
import { History } from 'history';
import { IHeaderInfoViewModel, initialHeaderInfoViewModel } from '../../../core/domain/viewModels/IHeaderInfoViewModel'
import { DistributeType } from '../../../core/common/Enums';
import { K1DistributionMethod } from '../../K1Distribution/K1DistributionMethod';
import { PathConstants, getRoutePathUrl } from '../../Common/Constants';
import { Body } from '../../Common/StepLayout/Body';
import { StepLayout } from '../../Common/StepLayout/StepLayout';
import { Next } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Next';
import { Previous } from '@sssuite-component-ui/multi-step-wizard/dist/Footer/Previous';
import { Footer } from '../../Common/StepLayout/Footer';
import TaxpayerHelper from '../../Helper/TaxpayerHelper';
import { IClientProcessViewModel } from '../../../core/domain/viewModels/IClientProcessViewModel'
import * as SignProcessStore from '../../../store/SignProcess/SignProcessStore';
import { GetK1EngagementType } from '../../../core/domain/models/IK1Distribute';
import { storeControllerIdInMemory } from '../../../core/services/dataAccess/DataService.Axios';
import { logger } from '../../../routes';
import { PreviousButtonIcon } from "../../Common/Icons/SvgIcons";
import * as HelperStore from "../../../store/Common/HelperStore";
import { Guid } from '../../../core/utilities/Guid';

type K1DistributionCompletedProps = {
	taxReturn: TaxDocument.ITaxReturn,
	headInfoViewModel: IHeaderInfoViewModel,
	companyData: ICompany,
	match: match;
	history: History;
	stepInfo: IClientProcessViewModel;
}
	& typeof CompanyStore.actionCreators
	& typeof TaxDocumentStore.actionCreators
	& typeof SignProcessStore.actionCreators
	& typeof HelperStore.actionCreators;


interface K1DistributionCompletedState {
	isProcessCompleted: boolean;
	isPreviewMode: boolean;
}

export class K1DistributionCompleted extends React.Component<K1DistributionCompletedProps, K1DistributionCompletedState> {
	private _params: any;
	constructor(props: K1DistributionCompletedProps) {
		super(props);
		this.state = {
			isProcessCompleted: false,
			isPreviewMode: false
		}
	}

	componentDidMount() {
		const param: any = this.props.match.params;
		logger.trackPageView('Completed Summary K1 Distribution Page', { "ClientId": param.clientId, "DocumentId": this.props.taxReturn.id });
		if (param && param.controllerId) {
			storeControllerIdInMemory(param.controllerId);
		}
		this.getSignProcessStatus(param.clientId);
		this.props.requestTaxDocument(param.clientId);
		this.props.requestCompanyDetails(param.clientId, true);
	}
	handleSetDistributeMethod = (type: DistributeType) => {
		const param: any = this.props.match.params;
		DistributeType.Manual === type
			? this.props.history.push(getRoutePathUrl(PathConstants.DistributeK1Manually, param.clientId, param.controllerId))
			: this.props.history.push(getRoutePathUrl(PathConstants.DistributeK1Electronically, param.clientId, param.controllerId));
	}
	//private clearState = () => {
	//    this.setState({
	//        hideNextButton: false,
	//        hidePreviousButton: false,
	//        isNextButtonDisable: false
	//    })
	//}
	gotoCompletionWizard = () => {
		const param: any = this.props.match.params;
		this.props.history.push(getRoutePathUrl(PathConstants.CompletionWizard, param.clientId, param.controllerId));
	}
	private getSignProcessStatus = (clientId: any) => {
		this.props.requestCurrentStepAndUserRole(clientId, this.handleRedirect);
	}
	private handleRedirect = (clientProcessState: IClientProcessViewModel) => {
		this._params = this.props.match.params;
		const url = TaxpayerHelper.getSignCompletedRedirectUrl(clientProcessState, this._params.clientId, this._params.controllerId);
		if (url && url.trim()) {
			this.props.history.push(url);
		}
		else {
			this.setState({ isProcessCompleted: true });
		}
	}

	redirectToOneHub = () => {
		const param: any = this.props.match.params;
		const clientId = this.props.stepInfo.currentstep.clientId == Guid.empty ? param.clientId : this.props.stepInfo.currentstep.clientId;
		this.props.getClientHubDomainURL(clientId, (oneHubDomainURL: string) => {
			window.location.href = oneHubDomainURL + "/distribute-k1s";
		})
	}

	public render() {

		const type = this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);

		return ((this.state.isProcessCompleted) &&

			<StepLayout className='select-k1-method-container'>
				<Body lg={12} md={12} sm={12} xs={12}
					title={`Distribute K-1 Documents to ${type}`}
					subTitle={`Select the method you would like to distribute your K-1's`}
					automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}>
					{this.props.taxReturn.formGroups.length > 0 &&
						<K1DistributionMethod
							taxReturn={this.props.taxReturn}
							onSetDistributeMethod={this.handleSetDistributeMethod}
							match={this.props.match}
							isPreviewMode={this.state.isPreviewMode}
							isK1LastActiveStep={false}
						/>
					}
				</Body>

				<Footer lg={12} md={12} sm={12} xs={12}>
					<div className={"steps-footer"}>
						<Previous automationTestId={"3F43C07D-73AD-4BB5-83D1-346B1E62ABC6"}
							text={"Back"} goToPreviousStep={this.gotoCompletionWizard} isActive={true}
							className="awesome-multi-steps-completed-btn-previous" previousButtonIcon={<PreviousButtonIcon />} />

						<Next automationTestId={"21596F53-89B5-4830-812E-96D126E7C1AE"} className="awesome-multi-steps-btn-done"
							text={"Done"}  goToNextStep={this.props.stepInfo.isAccessedFromOneHub ?
								this.redirectToOneHub : this.gotoCompletionWizard} isActive={true} buttonVariant="success" buttonSize="medium" />
					</div>
				</Footer>

			</StepLayout>);

	}
}
