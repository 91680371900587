import { ILogoutMessage } from "../../core/domain/models/ILogoutMessage";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { History } from "history";
import * as React from "react";
import IdleTimer from "react-idle-timer";
import { match, RouteComponentProps } from "react-router";
import { BroadcastChannel } from "broadcast-channel";
import { Toaster } from "../../components/Common/Notification/MarsNotifier";
import { Role, TaxReturnStatus } from "../../core/common/Enums";
import * as TaxDocument from "../../core/domain/models/ITaxReturn";
import * as DownloadableDocument from "../../core/domain/viewModels/IDownloadableDocumentsViewModel";
import { IHeaderInfoViewModel } from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { ILocalStore } from "../../core/utilities/LocalStore";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import * as AccountStore from "../../store/Common/AccountStore";
import * as EsignStore from "../../store/Common/EsignStore";
import * as HelperStore from "../../store/Common/HelperStore";
import * as TaxDocumentStore from "../../store/Common/TaxDocumentStore";
import * as CompanyStore from "../../store/Common/Company/CompanyStore";
import * as SignProcessStore from "../../store/SignProcess/SignProcessStore";
import { SessionTimeout } from "../Account/SessionTimeout";
import { OverlayLoader } from "../Common/Loader/OverlayLoader";
import Notification from "../Common/Notification/NotificationContainer";
import { Header } from "./Header";
import { initializeAppInsights } from "../Logger/AppInsights";
import { PathConstants } from "../Common/Constants";
import { isDraftReturnReviewed } from "../Helper/HelperFunction";
import {
  removeOpenedWindowStatus,
  generateControllerSessionId,
  windowUnloadStep,
  getClientBasicInfo,
  injectPendoScript,
} from "../Helper/HelperFunction";
import { ICompany } from "../../core/domain/models/company/Company";
import Footer from "./Footer";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";

declare global {
  interface Window {
    Variables: any;
    pendo: any;
  }
}
export type DefaultLayoutProps = {
  headerInfo: IHeaderInfoViewModel;
  match: match;
  history: History;
  loading: boolean;
  taxReturn: TaxDocument.ITaxReturn;
  requestMyDownload: (clientId: string) => any;
  deleteMyDownloads: (downloadId: string, clientId: string) => void;
  downloadK1Zip: (clientId: string, downloadId: string) => void;
  clearAllMyDownloads: (clientId: string) => void;
  signerData: ISignerModel[];
  downloadList: DownloadableDocument.IDownloadableDocumentsViewModel;
  iceServers: RTCIceServer[];
  getIceServers: (clientId: string) => void;
  getWalkMeScript: (id: string, callback: (script: string) => void) => void;
  isScreenShareEnabled: boolean;
  isPreviewMode: boolean;
  getWebRTCSignalRHubBaseURL: (
    id: string,
    callback: (signalRHubBaseURL: string) => void
  ) => void;
  webRTCSignalRHubBaseURL: string;
  companyData: ICompany;
  clientProcessData: IClientProcessViewModel;
  getClientHubDomainURL: (
    id: string,
    callback: (clientHubDomainURL: string) => void
  ) => void;
  checkIfUserExistsInOneHub: (
    clientId: string,
    callback: (isUserExists: boolean) => void
  ) => void;
  getOnehubRedirectURL: (
    clientId: string,
    callback: (redirectURL: string) => void
  ) => void;
  getOnehubRedirectURLForClientView: (
    clientId: string,
    callback: (redirectURL: string) => void
  ) => void;
} & typeof AccountStore.actionCreators &
  typeof HelperStore.actionCreators &
  typeof EsignStore.actionCreators &
  typeof TaxDocumentStore.actionCreators &
  typeof CompanyStore.actionCreators &
  typeof SignProcessStore.actionCreators &
  RouteComponentProps<{}>;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);
const channel = new BroadcastChannel("ssr-taxpayer");

const WarningVisibleMinutes = 1;

export interface IProfileData {
  companyId: number;
  companyName: string;
  clientGuid: string;
  clientName: string;
  emailAddress: string;
}

interface IDefaultLayoutState {
  showTimeout: boolean;
  idleSessionTimeoutInSeconds: number;
  profileData: IProfileData | undefined;
  isPendoInjected: boolean;
}

export class DefaultLayout extends React.Component<
  DefaultLayoutProps,
  IDefaultLayoutState
> {
  private idleTimer: any;
  private sessionRenew: any;

  constructor(props: DefaultLayoutProps) {
    super(props);
    this.idleTimer = React.createRef();
    this.state = {
      showTimeout: false,
      idleSessionTimeoutInSeconds: 0,
      profileData: undefined,
      isPendoInjected: false,
    };
  }

  checkOpenTaxWindowStatus = (param: any) => {
    if (param.controllerId) {
      let storedValue: any = localStorage.getItem(
        generateControllerSessionId(param.controllerId)
      );
      if (storedValue !== null) {
        let openTaxWindows: number = parseInt(storedValue);
        openTaxWindows += 1;
        localStorage.setItem(
          generateControllerSessionId(param.controllerId),
          openTaxWindows + ""
        );
      } else {
        localStorage.setItem(
          generateControllerSessionId(param.controllerId),
          "1"
        );
      }
    }
  };

  componentDidMount() {
    let param: any = this.props.match.params;
    initializeAppInsights(param.clientId);
    this.props.requestHeaderInfo(param.clientId, this.verifyDraftStatus);
    this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionRenew);
    this.props.requestAllTaxingAuthorities(param.clientId);
    this.props.requestTaxDocumentclientType(param.clientId);
    this.props.requestMyDownload(param.clientId);
    this.props.requestCompanyDetails(param.clientId);

    this.checkOpenTaxWindowStatus(param);

    if (param.controllerId) {
      window.addEventListener("beforeunload", (e: any) => {
        e.preventDefault();
        windowUnloadStep(param.controllerId, false);
        delete e["returnValue"];
      });
    }
    getClientBasicInfo(param.clientId, this.updateProfileData);
  }

  componentDidUpdate(
    prevProps: Readonly<DefaultLayoutProps>,
    prevState: Readonly<IDefaultLayoutState>
  ): void {
    if (!this.state.isPendoInjected && this.state.profileData) {
      const { companyId, companyName, clientGuid, clientName, emailAddress } =
        this.state.profileData;
      injectPendoScript(
        companyId,
        companyName,
        clientGuid,
        clientName,
        emailAddress,
        this.state.isPendoInjected,
        this.setIsPendoInjected
      );
    }
  }

  verifyDraftStatus = (headerInfo: IHeaderInfoViewModel) => {
    if(isDraftReturnReviewed(headerInfo.signatureStatus) && !this.props.match.path.includes('/taxreturnstatus')){
      let param: any = this.props.match.params;
      this.props.history.push(`${PathConstants.TaxReturnStatus}${TaxReturnStatus.DraftCompleted}/${param.clientId}`);
      return;
    }
  };

  setIsPendoInjected = (isPendoInjected: boolean) => {
    this.setState({ isPendoInjected });
  };

  updateProfileData = (data: IProfileData) => {
    this.setState({ profileData: data });
  };

  setSessionRenew = (sessionTimeoutValue: number) => {
    this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue });
    this.sessionRenew = setInterval(() => {
      this.renewSession();
    }, 1000 * 60 * (this.state.idleSessionTimeoutInSeconds / 60));
  };

  componentWillUnmount() {
    let param: any = this.props.match.params;

    if (param.controllerId) {
      removeOpenedWindowStatus(param.controllerId);
      window.removeEventListener("beforeunload", (e: any) => {
        e.preventDefault();
        windowUnloadStep(param.controllerId, false);
        delete e["returnValue"];
      });
    }
    clearInterval(this.sessionRenew);
  }

  public render() {
    return (
      //<LoadingOverlay
      //    active={this.props.loading}
      //    spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loading} />}>
      <div>
        {this.state.idleSessionTimeoutInSeconds > 0 && (
          <IdleTimer
            key="idleTimer"
            ref={this.idleTimer}
            onActive={this.onActive}
            element={document}
            onIdle={this.onIdle}
            debounce={250}
            timeout={
              1000 *
              60 *
              (this.state.idleSessionTimeoutInSeconds / 60 -
                WarningVisibleMinutes)
            }
          />
        )}
        <Header
          headerInfo={this.props.headerInfo}
          match={this.props.match}
          history={this.props.history}
          taxdocument={this.props.taxReturn}
          refreshTaxDocument={this.props.requestTaxDocument}
          updateEmailAddress={this.props.updateSpouseMail}
          updateMobileNumber={this.props.updateMobileNumber}
          signerData={this.props.signerData}
          updateTaxDocument={this.props.updateTaxDocument}
          downlistList={this.props.downloadList.myDownloadList}
          deleteMyDownloads={this.props.deleteMyDownloads}
          clearAllMyDownloads={this.props.clearAllMyDownloads}
          downloadK1Zip={this.props.downloadK1Zip}
          validateTimeBasedAccessCode={this.props.validateTimeBasedAccessCode}
          getIceServers={this.props.getIceServers}
          iceServers={this.props.iceServers}
          requestMyDownload={this.props.requestMyDownload}
          logout={this.props.logout}
          isScreenShareEnabled={this.props.isScreenShareEnabled}
          isPreviewMode={this.props.isPreviewMode}
          getWebRTCSignalRHubBaseURL={this.props.getWebRTCSignalRHubBaseURL}
          webRTCSignalRHubBaseURL={this.props.webRTCSignalRHubBaseURL}
          companyData={this.props.companyData}
          clientProcessData={this.props.clientProcessData}
          getClientHubDomainURL={this.props.getClientHubDomainURL}
          checkIfUserExistsInOneHub={this.props.checkIfUserExistsInOneHub}
          getOnehubRedirectURL={this.props.getOnehubRedirectURL}
          getOnehubRedirectURLForClientView={
            this.props.getOnehubRedirectURLForClientView
          }
        />
        <div id="content-wrapper" className="content-wrapper">
          <Notification />
          {this.props.children}
        </div>
        <Footer />
        <OverlayLoader />
        <Toaster />

        {this.state.idleSessionTimeoutInSeconds > 0 && (
          <SessionTimeout
            history={this.props.history}
            match={this.props.match}
            showModal={this.state.showTimeout}
            onStayAlive={this.onStayAlive}
            countDownMinutes={WarningVisibleMinutes}
            loggedInUserInfo={this.props.headerInfo.loggedInUserInfo}
            logout={this.props.logout}
          />
        )}
      </div>
    );
  }

  private onIdle = (e: any) => {
    this.idleTimer.current.reset();
    this.setState({ showTimeout: true });
  };

  private onActive = (e: any) => {
    this.setState({ showTimeout: false });
  };
  private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    let param: any = this.props.match.params;
    this.props.refreshToken(param.clientId, this.onCountDownComplete);
    this.setState({ showTimeout: false });
  };

  private renewSession = () => {
    if (!this.state.showTimeout) {
      let param: any = this.props.match.params;
      this.props.refreshToken(param.clientId, this.onCountDownComplete);
    }
  };
  onLogOutCompletion = (id: string) => {
    let param: any = this.props.match.params;
    const url = param.controllerId
      ? PathConstants.ControllerLogin + id
      : PathConstants.ClientLogin + id;
    this.props.history.push(url);
  };

  private onCountDownComplete = () => {
    if (
      this.props.headerInfo.loggedInUserInfo.role.toString() ===
      Role[Role.CPA].toString()
    ) {
      window.close();
    } else {
      let param: any = this.props.match.params;

      const message: ILogoutMessage = {
        isLoggedOut: true,
        clientGuid: param.controllerId ? param.controllerId : param.clientId,
      };

      channel
        .postMessage(message)
        .then((value: any) => {
          this.props.logout(
            param.controllerId ? param.controllerId : param.clientId,
            this.onLogOutCompletion
          );
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };
}
