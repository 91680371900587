
import { AxiosResponse } from 'axios';
import { Action, Reducer } from 'redux';
import { initializeAxios } from '../core/services/dataAccess/DataService.Axios';
import { actionTypes } from './ActionTypes';
import { NotificationAction, StatusType } from './Common/NotificationStore';
import { AppThunkAction } from './index';
import { SignProcessSteps } from '../components/Home/TaxpayerSignFlowManager';
import { IClientProcessViewModel, initialClientProcessModel } from '../core/domain/viewModels/IClientProcessViewModel'
import { ErrorMessages } from '../components/Common/Constants';
import { HeaderConstants } from '../components/Common/Constants';
import { DraftStatus, SignatureStatus, DocumentStatus } from '../core/common/Enums';


interface IThemeSettings {

	bgColorCode: string,
	foreColorCode: string
}

interface IBrandingSettings {
	coverPageSetting: IThemeSettings
}

interface ILoggedInUserInfo {
	role: string,
	permission: string
}


export interface IBaseViewModel {
	clientName: string,
	taxYear: string,
	companyName: string,
	companyLogoPath: string,
	companyWhiteLogoPath: string,
	contactPerson: any,
	brandingSettings: IBrandingSettings | null,
	loggedInUserInfo: ILoggedInUserInfo | null,
	draftStatus: DraftStatus,
	signatureStatus: SignatureStatus,
	documentStatus: DocumentStatus
}


export const initialHeaderInfo: IBaseViewModel = {
	clientName: "",
	taxYear: "",
	companyName: "",
	companyLogoPath: "",
	companyWhiteLogoPath: "",
	contactPerson: null,
	brandingSettings: null,
	loggedInUserInfo: null,
	draftStatus: DraftStatus.None,
	signatureStatus: SignatureStatus.None,
	documentStatus: DocumentStatus.None
}




interface RequestSignProcessAction {
	type: actionTypes.SIGN_PROCESS_REQUEST;
}
interface RequestCurrentStepAction {
	type: actionTypes.SIGN_PROCESS_STEP;
	data: IClientProcessViewModel;
}


interface ResponseSignProcessAction {
	type: actionTypes.SIGN_PROCESS_RESPONSE;
	data: number;
}

interface FailureSignProcessAction {
	type: actionTypes.SIGN_PROCESS_FAILURE;
	data: number;
}

interface UpdateLastVisitedStep {
	type: actionTypes.UPDATE_LAST_VISITED_PROCESS_STEP;
	data: SignProcessSteps;
}

export interface SignProcessState {
	data: number;
	clientprocessmodel: IClientProcessViewModel;
}

export const initialSignProcessState: SignProcessState = {
	data: 0,
	clientprocessmodel: initialClientProcessModel
}


type KnownAction =
	DispatchAction |
	NotificationAction;

type DispatchAction =
	ResponseSignProcessAction
	| RequestSignProcessAction
	| FailureSignProcessAction
	| RequestCurrentStepAction
	| UpdateLastVisitedStep








export const actionCreators = {
	requestHeaderDetails: (clientId?: string, successCallback?: (headerInfo: any) => void): AppThunkAction<any> => (dispatch, getState) => {

		return initializeAxios().get<any>("api/Coverpage/HeaderInfo/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.RECEIVE_HEADER_DETAILS,
						data: result.data
					});
					successCallback && successCallback(result.data);
				}
				else {
					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Error
					})

				}


			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
					statusType: StatusType.Error
				})
			}
			);


	},
	requestGroupHeaderDetails: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {

		return initializeAxios().get<any>("api/GroupedReturns/Coverpage/HeaderInfo/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.RECEIVE_HEADER_DETAILS,
						data: result.data
					});
				}
				else {
					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Error
					})

				}


			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
					statusType: StatusType.Error
				})
			}
			);


	},
	requestDelHeaderDetails: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {

		return initializeAxios().get<any>("api/Delegatee/HeaderInfo/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.RECEIVE_HEADER_DETAILS,
						data: result.data
					});
				}
				else {
					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Error
					})

				}


			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
					statusType: StatusType.Error
				})
			}
			);


	},
	requestCCHeaderDetails: (clientId?: string): AppThunkAction<any> => (dispatch, getState) => {

		return initializeAxios().get<any>("api/CcRecipient/HeaderInfo/" + clientId)
			.then(function (response: AxiosResponse<any>) {
				let result = response.data;
				if (result.isSuccess) {
					dispatch({
						type: actionTypes.RECEIVE_HEADER_DETAILS,
						data: result.data
					});
				}
				else {
					let errorDesc = result.errorDescription;
					dispatch({
						type: actionTypes.NOTIFICATION,
						statusMessage: errorDesc,
						statusType: StatusType.Error
					})

				}


			})
			.catch((e: any) => {
				dispatch({
					type: actionTypes.NOTIFICATION,
					statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
					statusType: StatusType.Error
				})
			}
			);
	},
	getClientHubDomainURL:(id: string, callback: (url: string) => void): AppThunkAction<KnownAction> =>(dispatch, getState) => {
      return initializeAxios()
        .get<string>("api/Coverpage/GetClientHubDomainURL/" + id)
        .then(function (response: AxiosResponse<string>) {
          callback(response.data);
        })
        .catch(function (error: any) {
			dispatch({
				type: actionTypes.NOTIFICATION,
				statusMessage: HeaderConstants.ErrorMessage.RetrieveFailed,
				statusType: StatusType.Error
			})
        });
    },
}

export const reducer: Reducer<IBaseViewModel> = (state: IBaseViewModel = initialHeaderInfo, incomingAction: Action): any => {
	const action = incomingAction as any;
	const currentState = Object.assign({}, state);
	console.log("in reducers", action);
	switch (action.type) {
		case actionTypes.RECEIVE_HEADER_DETAILS:
			let rData = action.data;
			currentState.clientName = rData.clientName;
			currentState.taxYear = rData.taxYear;
			currentState.companyName = rData.companyName;
			currentState.companyLogoPath = rData.companyLogoPath;
			currentState.companyWhiteLogoPath = rData.companyWhiteLogoPath;
			currentState.draftStatus = rData.draftStatus;
			currentState.signatureStatus = rData.signatureStatus;
			currentState.documentStatus = rData.documentStatus;
			if (!rData.contactPerson) {
				currentState.contactPerson = { firstName: '', lastName: '', middleName: '', phoneNumber: '', extension: '', email: '', };
			}
			else {
				currentState.contactPerson = rData.contactPerson;
			}
			currentState.brandingSettings = rData.brandingSettings;
			currentState.loggedInUserInfo = rData.loggedInUserInfo;



			return ({ ...currentState });

		default:
			return currentState || initialSignProcessState;
	}
}; 