import * as React from "react";
import { ITaxReturn } from "../../core/domain/models/ITaxReturn";
import { DistributeType } from "../../core/common/Enums";
import { Common, K1ShareHolderConstants } from "../Common/Constants";
import { logger } from "../../routes";
import { match } from "react-router";
import { TileComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../Helper/HelperFunction";

interface K1DistributionMethodProps {
  taxReturn: ITaxReturn;
  clearParentState?: () => void;
  onSetDistributeMethod: (type: DistributeType) => void;
  match: match;
  isPreviewMode: boolean;
  isK1LastActiveStep: boolean;
}

export class K1DistributionMethod extends React.Component<
  K1DistributionMethodProps,
  {}
> {
  constructor(props: any) {
    super(props);
    this.props.clearParentState && this.props.clearParentState();
  }

  componentDidMount() {
    let param: any = this.props.match.params;
    logger.trackPageView("K1 Distribution Page", {
      ClientId: param.clientId,
      DocumentId: this.props.taxReturn.id,
      PreviewMode: this.props.isPreviewMode,
    });
  }

  public render() {
    const k1StepDisabled = this.props.isK1LastActiveStep;

    return (
      <div
        id="k1Distribution"
        data-test-auto="568B4554-8CFD-4965-9F71-084E1365E564"
      >
        <div className="cards-container">
          <div className="step-img-btn cards-content">
            <div
              data-test-auto="9EC15877-B832-4107-AC19-47E29D43818A"
              className="cards"
              tabIndex={0}
              onKeyDown={(e: any) =>
                k1StepDisabled
                  ? null
                  : handleKeyDown(e, () =>
                      this.props.onSetDistributeMethod(
                        DistributeType.Electronic
                      )
                    )
              }
            >
              <TileComponent
                onClick={() =>
                  k1StepDisabled
                    ? null
                    : this.props.onSetDistributeMethod(
                        DistributeType.Electronic
                      )
                }
                tileContent={
                  <Common.Images.ElectronicK1DistributionIcon data-test-auto="F9465A41-0FAD-48F4-948D-D2427987F24D" />
                }
                tileDetail={
                  <>
                    <div
                      className={`image-caption-title ${
                        k1StepDisabled && "k1StepDisabled"
                      }`}
                    >
                      {K1ShareHolderConstants.ElectronicCaption}
                    </div>
                    <span
                      className={`image-caption-subtitle ${
                        k1StepDisabled && "k1StepDisabled"
                      }`}
                      data-test-auto="D7A5DC2A-2F4D-42F8-8CBA-5B7B251E105E"
                    >
                      {K1ShareHolderConstants.ElectronicSubtitleCaption}
                    </span>
                  </>
                }
                tileClassname={k1StepDisabled && "k1StepDisabled"}
              />
            </div>

            <div
              data-test-auto="9EC15877-B832-4107-AC19-47E29D43818A"
              className="cards"
              tabIndex={0}
              onKeyDown={(e: any) =>
                k1StepDisabled
                  ? null
                  : handleKeyDown(e, () =>
                      this.props.onSetDistributeMethod(DistributeType.Manual)
                    )
              }
            >
              <TileComponent
                onClick={() =>
                  k1StepDisabled
                    ? null
                    : this.props.onSetDistributeMethod(DistributeType.Manual)
                }
                tileContent={
                  <Common.Images.MailHardCopyIcon data-test-auto="7DB4B109-0662-4BC7-B88A-1E000C182EC8" />
                }
                tileDetail={
                  <>
                    <div
                      className={`image-caption-title ${
                        k1StepDisabled && "k1StepDisabled"
                      }`}
                    >
                      {K1ShareHolderConstants.MailHardCopyCaption}
                    </div>
                    <span
                      className={`image-caption-subtitle ${
                        k1StepDisabled && "k1StepDisabled"
                      }`}
                      data-test-auto="49D75876-02D6-4DA4-9F92-AC756270AA4F"
                    >
                      {K1ShareHolderConstants.MailHardCopySubtitleCaption}
                    </span>
                  </>
                }
                tileClassname={k1StepDisabled && "k1StepDisabled"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
