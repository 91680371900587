import { ApplicationState } from "../../store/index";
import { actionCreators as accountAction } from '../../store/Common/AccountStore';
import { DelegateeDefaultLayout } from "./DelegateeDefaultLayout";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { ClientType } from '../../core/common/Enums';
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as delegateeActionCreators } from '../../store/DelegateeSignerStore';
import { IDelegateeSignerDetails, IDelegateeConsentInfo } from '../../core/domain/models/IDelegateeSignerModel';
import { actionCreators as helperAction } from '../../store/Common/HelperStore';
const loadingSelector = createLoadingSelector(['DELEGATEE_HEADERINFO', 'CCRECIPIENT_DOCUMENTS_FOR_DOWNLOAD']);

function mapStateToProps(state: ApplicationState) {
    return {
        delegateeHeaderInfo: state.delegateeHeaderInfo,
        delegateeSigner: state.delegateeSigner,
        loading: loadingSelector(state),
        companyData: state.companyData.data,
        clientProcessData: state.signProcessData.clientprocessmodel
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestDelegateeHeaderInfo: (id: string, failedcallback?: () => void, successCallback?: (headerInfo: any) => void) => dispatch(accountAction.requestDelegateeHeaderInfo(id, failedcallback, successCallback)),

        requestHeaderInfo: (id: string) => dispatch(accountAction.requestHeaderInfo(id)),

        refreshToken: (clientId: string, callback: any) => dispatch(accountAction.refreshToken(clientId, callback())),

        logout: (clientGuid: string, callback: (id: string) => void) => dispatch(accountAction.logout(clientGuid, callback)),

        getSessionTimeOutSeconds: (clientId: string, callBack?: (sessionTimeout: number) =>void) => dispatch(accountAction.getSessionTimeOutSeconds(clientId, callBack)),

        updateMobileNumber: (clientGuid: string, id: string, number: string, countryCodeValue: string,
            ssnValue: string, clientType: ClientType, callback: () => void) =>
            dispatch(accountAction.updateMobileNumber(clientGuid, id, number, countryCodeValue, ssnValue, clientType, callback)),

        requestRecipientHeaderInfo: (id: string) => dispatch(accountAction.requestRecipientHeaderInfo(id)),

        requestDelegateeDetails: (clientId: string) => dispatch(delegateeActionCreators.requestDelegateeDetails(clientId)),

        updateDelegateeMobileNumber: (clientId: string, countryCode: string, mobileNumber: string, callback?: () => void) =>
            dispatch(delegateeActionCreators.updateDelegateeMobileNumber(clientId, countryCode, mobileNumber, callback)),

        assignToDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, callback: () => void) =>
            dispatch(delegateeActionCreators.assignToDelegatee(clientId, delegatee, callback)),

        updateDelegatee: (clientId: string, delegatee: IDelegateeSignerDetails, callback: () => void) =>
            dispatch(delegateeActionCreators.updateDelegatee(clientId, delegatee, callback)),

        cancelDelegation: (clientId: string, remarks: string, taxYear: number, callback: () => void) =>
            dispatch(delegateeActionCreators.cancelDelegation(clientId, remarks, taxYear, callback)),

        requestDelegateeDocumentReviewModel: (clientId: string) =>
            dispatch(delegateeActionCreators.requestDelegateeDocumentReviewModel(clientId)),

        requestDelegateeDownloadableDocuments: (clientId: string) =>
            dispatch(delegateeActionCreators.requestDelegateeDownloadableDocuments(clientId)),

        saveDeclinedRemarks: (clientId: string, remarks: string, callback: () => void) =>
            dispatch(delegateeActionCreators.saveDeclinedRemarks(clientId, remarks, callback)),

        requestTaxClientDelegateeDetails: (clientId: string) =>
            dispatch(delegateeActionCreators.requestTaxClientDelegateeDetails(clientId)),

        requestGroupHeaderInfo: (id: string) => dispatch(accountAction.requestGroupHeaderInfo(id)),

        saveDelegateeConsentDetails: (clientId: string, consentDetails: IDelegateeConsentInfo, callback: () => void) =>
            dispatch(delegateeActionCreators.saveDelegateeConsentDetails(clientId, consentDetails, callback)),
        
        getClientHubDomainURL: (clientGuid: string, callback: (hubDomainURL: string) => void) => 
            dispatch(helperAction.getClientHubDomainURL(clientGuid, callback)),

        checkIfUserExistsInOneHub: (clientGuid: string, callback: (isUserExists: boolean) => void) =>
            dispatch(helperAction.checkIfUserExistsInOneHub(clientGuid, callback)),
        
        getOnehubRedirectURL: (clientGuid: string, callback: (onehubRedirectURL: string) => void) =>
            dispatch(helperAction.getOnehubRedirectURL(clientGuid, callback)),
        dispatch
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DelegateeDefaultLayout));

