import { connect } from 'react-redux';
import TaxReturnStatusViewContainer from "./TaxReturnStatusView";
import * as  CoverPageStore from "../../../store/CoverPage/CoverPageStore";
import * as HeaderStore from "../../../store/HeaderStore";

export default connect(
    (state: any) => ({
        initialCoverState: state.CoverPageReducer,
        headerInfoState: state.HeaderPageReducer,
    }),
    {
        ...CoverPageStore.actionCreators,
        ...HeaderStore.actionCreators,
    }
)(TaxReturnStatusViewContainer as any);
