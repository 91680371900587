import * as React from "react";
import { ManualSignConstants } from "../../Common/Constants";
import { SvgIconManualUpload } from "../../../components/Common/Icons/BrandIcons";
import { ButtonComponent } from "cp-common-ui-components";

interface UploadHeaderProps {
  minimized?: boolean;
  isUploadDisabled?: boolean;
}

export const UploadHeader: React.FunctionComponent<UploadHeaderProps> = ({
  minimized,
  isUploadDisabled
}) => {
  let fileUploadArea = () => {
    return minimized ? (
      <div
        data-test-auto="609FAE9E-6540-469A-BA40-922DC11D1E68"
        className={
          "filepicker upload-area minimized d-flex justify-content-center align-items-center"
        }
      >
        <SvgIconManualUpload />
        <div className="ml-4">
          <h6>{ManualSignConstants.UploadAreaText}</h6>
          <div style={{width:270}}>
            <span className="manual-upload-supported-files">
              {" "}
              {ManualSignConstants.SupportedFiles}{" "}
            </span>
          </div>
        </div>
        <ButtonComponent
          data-test-auto={"C113515F-B901-4B2C-8DC2-E7F3F3ECCD80"}
          variant={"primary"}
          size={"medium"}
          buttonClassName={"btn-upload rounded-pill mt-4 ml-4"}
          disabled={isUploadDisabled}
        >
          {ManualSignConstants.UploadAreaButtonText}
        </ButtonComponent>
      </div>
    ) : (
      <React.Fragment>
        <div
          className={
            "filepicker upload-area d-flex justify-content-center align-items-center flex-column"
          }
          data-test-auto="FB2FA195-8F1A-4422-964D-3921950C8F8D"
        >
          <SvgIconManualUpload />
          <div>
            <h6> {ManualSignConstants.UploadAreaText}</h6>
          </div>
          <div style={{width:270}}>
            <span className="manual-upload-supported-files">
              {" "}
              {ManualSignConstants.SupportedFiles}{" "}
            </span>
          </div>
          <ButtonComponent
            data-test-auto={"C113515F-B901-4B2C-8DC2-E7F3F3ECCD80"}
            variant={"primary"}
            size={"medium"}
            buttonClassName={"btn-upload rounded-pill mt-4"}
            disabled={isUploadDisabled}
          >
            {ManualSignConstants.UploadAreaButtonText}
          </ButtonComponent>
        </div>        
      </React.Fragment>
    );
  };

  return <div className={"dz-open-file-browse"}>{fileUploadArea()}</div>;
};
